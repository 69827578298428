/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from '../../common/Controller';
import { UiLoginHelper } from '../../common/helpers/UiLoginHelper';
import { UsernameInput, PasswordInput, NumericOTPInput } from '../../common/components/TextInputs';
import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme'
const isVapt = true
export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = UiLoginHelper.UserNameType.EMAIL;

    let _currentPage = null ;
    if (AppConfig.allowLocal){
      _currentPage = UiLoginHelper.Page.LOGIN;
    } else if (AppConfig.SSO && AppConfig.SSO.IDP_PROVIDER == "Google") {
      _currentPage = UiLoginHelper.Page.GOOGLE_SIGN_IN;
    } else if (AppConfig.SSO && AppConfig.SSO.IDP_PROVIDER == "Azure") {
      _currentPage = UiLoginHelper.Page.AZURE_SIGN_IN;
    } else {
      _currentPage = isVapt ? UiLoginHelper.Page.LOGIN : UiLoginHelper.Page.LOOKUP
    }

    // Original code
    // let _currentPage = AppConfig.allowLocal ? UiLoginHelper.Page.LOGIN : (AppConfig.SSO ? UiLoginHelper.Page.AZURE_SIGN_IN : UiLoginHelper.Page.LOOKUP),

    this.state = {
      currentPage: _currentPage,
      loadingAnimation: false,
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    };
    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: false,
      autoLogin: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    Controller.get().userMgr().navigateUserToHome();
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css([Styles.loginPanel, this.props.loginPanelStyle])}>


          <div className={css(Styles.logoImageContainer)}>
            <img
              src={require('../../assets/images/logo.png')}
              alt='Logo'
              className={css(Styles.logoImage)} />
          </div>

          <div className={css(Styles.loginMessageContainer)}>
            {this.state.message.length > 0 &&
              <p className={css(Styles.loginMessage)}>{this.state.message}</p>
            }
          </div>

          {this.state.currentPage === UiLoginHelper.Page.AZURE_SIGN_IN &&
            this.signUpWithAzure()
          }
          {this.state.currentPage === UiLoginHelper.Page.GOOGLE_SIGN_IN &&
            this.signUpWithGoogle()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
            this.welcomeView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
            this.signUpView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
            this.signUpOTPView()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOGIN &&
            this.loginView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
            this.resetPasswordView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
            this.resetPasswordOTPView()
          }
        </div>
      </div>
    );
  }

  signUpWithGoogle() {
    return (
      <div>

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLoginIdp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            SIGN IN WITH GOOGLE
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  signUpWithAzure() {
    return (
      <div>

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLoginIdp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            SIGN IN WITH AZURE
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  welcomeView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
        />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Continue
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder='Set a new password'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
        />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Signup
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        <a className={css(Styles.loginLink)} onClick={this.onLoginClicked.bind(this)}><p>Already have an account ? Login</p></a>
      </div>
    )
  }

  signUpOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Submit
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Login
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        {!AppConfig.allowLocal &&
          <div>
            <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>Reset password? Get OTP</p></a>
            <a className={css(Styles.loginLink)} onClick={this.onSignupClicked.bind(this)}><p>New User ? Sign Up</p></a>
          </div>
        }
      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Get OTP
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  resetPasswordOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })} />

        <PasswordInput
          label={"Enter new password"}
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitConfirmResetPassword()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Submit
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  onStartoverClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP);
  }

  onLoginClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.LOGIN);
  }

  onSignupClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.SIGNUP);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundSize: 'cover',
    minHeight: '100vh',
    backgroundImage: `url(${require('../../assets/images/login-bg.jpg')})`,
  },
  loginPanel: {
    borderRadius: 8,
    backgroundColor: '#262930c9',
    textAlign: 'center',
    width: 300,
    padding: 20,
    '@media (max-width: 767px)': {
      width: 280,
      padding: 10,
      margin: 5,
    },
    '@media (max-width: 320px)': {
      width: 240,
      padding: 10,
      margin: 5
    }
  },
  loginMessageContainer: {
    margin: 'auto'
  },
  loginMessage: {
    marginTop: 10,
    fontSize: 14,
    color: 'white'
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: 'white',
    fontSize: 14
  },
  paddingTop20px: {
    paddingTop: 20
  },
  marginLeft: {
    marginLeft: 10
  },
  termsContainer: {
    marginTop: 10,
    fontSize: 14,
    color: 'white'
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
  },
  logoImageContainer: {
    width: 232,
    margin: 'auto',
    borderRadius: 5,
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 5,
  },
})
