import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { SettingsComponent } from '../components/SettingsComponent';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';
import { SimpleTabs } from '../components/Tab';

export class CamSettings extends Component {
  constructor(props) {
    super(props);
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.camId = this.props.match.params.camid ? this.props.match.params.camid : null;
    this.orgId = (this.props.location && this.props.location.state) ? this.props.location.state.orgId : null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      showWFH:true,	
      showOffice:false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  fetchItems = () => {
    const thisObj = this;
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cams', thisObj.camId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        for (const f of AppConfig.camTypes) {
          if (f.key === results[0].type) {
            this.data = f
            break;
          }
        }
        if(!results[0]['officeSettings']){
          results[0]['officeSettings']= {['settings'] : {}}
        }
        this.setState({
          cam: results[0],
          fetchState: ApiHelper.State.READY,
        }, ()=>{
          this.fetchPlaces()
        })
      }.bind(this))
  }

  handleTabPanelChange = (value) => {
    if (value == 0) {
      this.setState({
        showWFH: true,
        showOffice: false
      })
    } else {
      this.setState({
        showWFH: false,
        showOffice: true
      })
    }
  }	

  fetchPlaces = () => {

    ApiHelper.call(
      {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["places", this.placeId]),
      },
      function (err, result) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err),
          });
          return;
        }
        if (result.cams.length > 0) {
          for (let index = 0; index < result.cams.length; index++) {
            const element = result.cams[index];
            if (element._id == this.state.cam._id) {
              this.setState({
                latestEventImageUrl: element.latestEventImageUrl
              })
              break
            }

          }
        }

      }.bind(this)
    );
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={'/places/' + this.placeId + '/cams'}
          statSuffix={ this.state.cam && this.state.cam.type == 'alert' ? 'CAM SETTINGS' : 'AGENT SETTINGS'}
          camId = {this.state.cam._id}
          title={this.state.cam.name}
          camType = {this.state.cam.type ? this.state.cam.type :""}
          latestEventImageUrl = {this.state.latestEventImageUrl ? this.state.latestEventImageUrl  : ""}
        />
        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
          {this.state.cam.type == 'general' && <SimpleTabs numberOfTabs={['Work From Home', 'Work From Office']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}

            {this.data && this.data.features && this.state.showWFH &&
              <SettingsComponent
                data={this.data}
                item={this.state.cam}
                itemId={this.camId}
                itemName={'cams'}
                apiBodyPrefix={'camData'}
                camSettingClass={true}
              />
            }
            {
              this.state.showOffice &&
              <SettingsComponent
                data={this.data}
                item={this.state.cam.officeSettings}
                itemId={this.camId}
                itemName={'cams'}
                apiBodyPrefix={'camData'}
                showOffice={this.state.showOffice}
                camSettingClass={true}

              />
            }
            {!this.data &&
              < p className={css(Styles.emptyDataContainer)}>{Helper.getString('noItemsFound')}</p>
            }
          </div>
        </div>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
    '@media(max-width: 768px)': {
      width: '90%',
    }
  },
  emptyDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flex: 1,
  },
})
