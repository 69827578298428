/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import 'rc-slider/assets/index.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

export class SearchTextField extends Component {
  constructor(props) {
    super(props);
    this.searchField = {
      key: 'searchText',
      label: this.props.label || 'Search Events',
      type: 'text'
    }
    this.state = {
      searchText: ''
    }
  }

  onChange = e => {
    if(e.key == "Enter" && this.props.allowOnEnterSearch){
      this.setState({
        [e.target.name]: e.target.value,
      }, () => this.props.searchHandlerByEnter(this.state.searchText));
    }else{

    this.setState({
      [e.target.name]: e.target.value,
    }, () => this.props.searchHandler(this.state.searchText));
   }
  }

  searchClose = () => {
    this.setState({ searchText: '' }, () => this.props.searchHandler(this.state.searchText))
  }

  render = () => {
    return (
      <TextField
        id={this.searchField.key}
        placeholder={this.searchField.label}
        name={this.searchField.key}
        className={css(Styles.inputCss)}
        value={this.state.searchText}
        onChange={e => this.onChange(e)}
        onKeyDown={(e)=>{
          if(e.key == 'Enter' && this.props.allowOnEnterSearch){
            this.onChange(e)
          }
        }}
        margin='normal'
        type={this.searchField.type}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: css(Styles.textFieldContentInput),
          },
          endAdornment: (this.props.hideIcon ? <></> :
            <InputAdornment position='end'>
              {this.state.searchText === '' ?
                <img src={require('../../assets/images/search-img.svg')}
                  className={css(Styles.controlImg)} />
                :
                <img src={require('../../assets/images/roundClose-img.svg')}
                  className={css(Styles.controlImg)} onClick={() => this.searchClose()} />
              }
            </InputAdornment>),
        }}
        InputLabelProps={{
          classes: {
            root: css(Styles.textFieldContent),
          }
        }}
      />
    )
  }
}