/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import Icon from '@material-ui/core/Icon';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ExcelDocUploader } from '../common/components/ExcelDocUploader';
import { CustomDialog } from '../components/CustomDialog';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { PopOverV2 } from '../components/PopOverV2';
import { TableListV2 } from '../components/TableListV2';
import { Stats } from '../components/Stats';
import { Controller } from '../common/Controller';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import { AppConfig } from '../AppConfig';
import { appData } from '../data/AppData';
import { StatsComponent } from './ClientPlace/StatsComponent';
import { FileDownloader } from '../common/helpers/FileDownloader'
import moment from 'moment';
import { orgData } from './GuestUsersDetails/Data';
import { Vehicle } from '../components/Vehicle'

export class Users extends Component {
  constructor(props) {
    super(props);
    this.orgId = (this.props.match && this.props.match.params) ? this.props.match.params.id : null
    this.responseByKey = {}
    this.cams = []
    this.places = []
    this.parentAttributes = {}
    this.orgView = (this.props.match && this.props.match.url === '/orgs/' + this.orgId) ? true : false
    this.state = {
      updateMemberDialog: false,
      verifyMemberDialog: false,
      bulkUserUploadDialog: false,
      dialogAction: '',
      dialogTitle: '',
      vehicles: [],
      addOwnDialog: false,
      editOwnDialog: false,
      addGuestVehicleDialog: false,
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      inProcess: false,
      showStats: false,
      fetchState: ApiHelper.State.LOADING,
      auditTrailEnabled: false,
      downloadAuditForAll:false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setFields()
    const settings = Controller.get().userMgr().getSettings()
    let orgValue = null
    if (settings && settings.userSettings && settings.userSettings.orgStat) {
      if (settings.userSettings.orgStat === 'ON') {
        orgValue = true
      } else {
        orgValue = false
      }
      this.setState({
        showStats: orgValue
      })
    }

    if (this.orgView) {
      this.fetchItemsForOrgView()
    } else {
      this.fetchItems();
    }
  }

  setFields = () => {
    this.userFields = []
    this.auditFields = []

  }

  fetchItems = () => {
    const queryParams = { orgId: this.orgId }
    this.setState({
      errMsg: null
    })
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], queryParams) }, callback)
        }
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        this.org = Controller.get().userMgr().getCurrentOrg()

        for (const item of results[0]) {
          if (item.orgs && item.orgs.length > 0) {
            item.role = item.orgs[0].roles
          }
        }
        this.setState({
          users: results[0],
          fetchState: ApiHelper.State.READY
        })

      }.bind(this))
  }
	
  getVehicleInfo = (vehicleQueryParams, results) => {
    return new Promise((res, rej) => {
      if (results[1] && results[1].type != 'society') {
        results.push([])
        res(results)
      } else {
        let options = { method: 'GET', endPoint: ApiHelper.makeUrlPath(['vehicles'], vehicleQueryParams) }
        ApiHelper.call(options, function (err, data) {
          if (err) {
            rej(err)
          } else {
            results.push(data ? data : [])
            res(results)
          }
        }.bind(this))
      }
    })
  }

  fetchItemsForOrgView = () => {
    let this2 = this
    const userId = Controller.get()
      .userMgr()
      .getUserId();
    const orgId = this.orgId;

    const queryParams = { orgId };
    const vehicleQueryParams = { orgId, userId };

    this.setState({
      errMsg: null
    })
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], queryParams) }, callback)
        },
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', orgId]) }, callback)
        },
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], queryParams) }, callback)
        }
        // ,
        // function (callback) {
        //   ApiHelper.call(
        //     { method: 'GET', endPoint: ApiHelper.makeUrlPath(['vehicles'], vehicleQueryParams) }, callback)
        // },
      ],

      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        this2.getVehicleInfo(vehicleQueryParams, results).then((results)=>{


        if (!results || results.length !== 4) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg()
          })
          return
        }

        let totalCams = []
        for (const item of results[2]) {
          totalCams = totalCams.concat(item.cams)
        }

        this.cams = totalCams
        this.places = results[2]
        this.org = results[1]
        if (AppConfig.orgTypes && AppConfig.orgTypes.length !== 0) {
          this.parentAttributes = AppConfig.orgTypes[0].attributes || {}
        }

        for (const item of results[0]) {
          if (item.orgs && item.orgs.length > 0) {
            item.role = item.orgs[0].roles
            item.managerType = item.orgs[0].managerType

          }
        }

        if (results[3] && results[3].length > 0) {
          for (const f of results[3]) {
            f.lp = f.lp.toUpperCase();
            if (f.type === "GUEST") {
              f.name = f.guestInfo && f.guestInfo.name;
              f.email = f.guestInfo && f.guestInfo.email;
              f.phone = f.guestInfo && f.guestInfo.phone;
            } else {
              f.name = f.ownerInfo && f.ownerInfo.name;
              f.email = f.ownerInfo && f.ownerInfo.email;
              f.phone = f.ownerInfo && f.ownerInfo.phone;
            }

            if (f.expireAt) {
              f.expireAt = new Date(f.expireAt).toString()
            } else {
              f.expireAt = "NA";
            }
            f.key = f.userlpId;
          }
        }

        this.setState({
          users: results[0],
          logoUrl: results[1].logoUrl,
          places: results[2],
          vehicles: results[3],
          fetchState: ApiHelper.State.READY
        })
      }).catch((err)=>{
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
      })
        }.bind(this))
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setFields()
    this.setState({
      updateMemberDialog: false,
      verifyMemberDialog: false,
      addOwnDialog: false,
      addGuestVehicleDialog: false,
      bulkUserUploadDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null,
      auditTrailEnabled: false
    })
  }

  getOrgSpecificsOrgId = () => {
    delete this.responseByKey.cred
    if (this.responseByKey &&
      this.responseByKey.orgs) {
      for (const org of this.responseByKey.orgs) {
        if (org._id === this.orgId) {
          this.responseByKey.role = org.roles
          this.responseByKey.managerType = org.managerType

          this.responseByKey.placeIdsEnabled = org.placeIdsEnabled
        }
      }
    }
  }

  showAddMemberDialog = () => {
    this.setState({
      verifyMemberDialog: true,
      dialogTitle: 'Add a Member',
    })
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false
    })
  }

  showBulkUserUploadDialog = () => {
    this.setState({
      bulkUserUploadDialog: true,
      dialogTitle: 'Add multiple members'
    })
  }

  showDeleteMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    const thisObj = this
    this.verifyUser(function (err, result) {
      if (err) return
      thisObj.setState({
        anchorEl: null,
        updateMemberDialog: true,
        dialogTitle: 'Delete a Member',
        dialogAction: 'delete',
      })
    })
  }

  showAuditDialog = (allUsers) => {
    if (!allUsers) {
      this.responseByKey = this.state.selectedItem
      const thisObj = this
      this.verifyUser(function (err, result) {
        if (err) return
        thisObj.setState({
          anchorEl: null,
          updateMemberDialog: true,
          dialogTitle: 'Audit Trail',
          dialogAction: 'download',
          auditTrailEnabled: true,
          downloadAuditForAll: false
        })
      })
    } else {
      this.setState({
        anchorEl: null,
        updateMemberDialog: true,
        dialogTitle: 'Audit Trail',
        dialogAction: 'download',
        auditTrailEnabled: true,
        downloadAuditForAll: true
      })
    }

  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    const thisObj = this
    this.verifyUser(function (err, result) {
      if (err) return
      thisObj.setState({
        anchorEl: null,
        updateMemberDialog: true,
        dialogTitle: 'Edit a Member',
        dialogAction: 'edit',
      })
    })
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteMemberDialog()
    } else if (key === 'audit') {
      this.showAuditDialog()
    }
  }

  editClick = (e, item) => {
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  setValue = (value, key) => {
    this.responseByKey[key] = value
  }

  addMembers = (items) => {
    this.setState({
      inProcess: true,
      errMsg: null
    })
    let user = {}
    const userData = []
    for (const item of items) {
      user = {
        email: item.email,
        org: {
          _id: this.orgId,
          roles: item.role
        },
        cred: 'test@123',//TODO: pwd hardcoded
        name: item.name,
        phone: item.mobile,
        vehicleNumbers: item.vehicleNumbers
      }
      Helper.removeNullValues(user)
      userData.push(user)
    }
    let options = {}
    options = {
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath(['users']),
      jsonBody: {
        userData: userData,
        action: 'ADD_MULTIPLE'
      }
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getString(err.message),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        if (this.orgView) {
          this.fetchItemsForOrgView()
        } else {
          this.fetchItems();
        }
      }
    }.bind(this))
  }

  addMember = () => {
    this.setState({
      inProcess: true,
      errMsg: null
    })
    let options = {}
    const userData = {}
    for (const uField of this.userFields) {
      userData[uField.key] = this.responseByKey[uField.key]
    }

    if (this.orgId === 'PRODUCT') {
      userData.org = {
        _id: this.orgId,
        roles: ['ADMIN']
      }
    } else {
      userData.org = {
        _id: this.orgId,
        roles: this.responseByKey.role,
        placeIdsEnabled: this.responseByKey.placeIdsEnabled,
        managerType: this.responseByKey.managerType
      }
    }

    const id = this.state.selectedItem ? this.state.selectedItem._id : null

    if (!id) {
      options = {
        method: 'POST',
        endPoint: ApiHelper.makeUrlPath(['users']),
        jsonBody: {
          userData: userData
        }
      }
    } else {
      const body = {
        userData: userData
      }
      if (this.responseByKey.cred && this.responseByKey.cred.length > 0) {
        body.userData.cred = this.responseByKey.cred
      } else {
        delete body.userData.cred
      }
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['users', id]),
        jsonBody: body
      }
    }
    Helper.removeNullValues(options.jsonBody.userData)
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        if (this.orgView) {
          this.fetchItemsForOrgView()
        } else {
          this.fetchItems();
        }
      }
    }.bind(this))
  }

  deleteMember = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }
    this.setState({
      inProcess: true,
      errMsg: null
    })
    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['users', id], { orgId: this.orgId }),
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getString(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        if (this.orgView) {
          this.fetchItemsForOrgView()
        } else {
          this.fetchItems();
        }
      }
    }.bind(this))
  }

  validateDates = () => {
    let fromDate = this.responseByKey.fromDate
    let toDate = this.responseByKey.toDate
    if (!fromDate || !toDate) {
      this.setState({
        errMsg: Helper.getErrorMsg("Please enter date"),
        inProcess: false,
      })
    return
    }
    let diff = moment(toDate).diff(moment(fromDate), 'days')
    if (diff >= 0 && diff <= 30) {
      return true
    } else {
      if (diff < 0) {
        this.setState({
          errMsg: Helper.getErrorMsg("From date can not be greater than To Date"),
          inProcess: false,
        })
      } else {
        this.setState({
          errMsg: Helper.getErrorMsg(" You can not download more than 30 days of audit trail"),
          inProcess: false,
        })
      }
      return false
    }
  }

  downloadAuditTrail = () => {
    if (this.validateDates()) {
      var id
      if (!this.state.downloadAuditForAll) {
        id = this.state.selectedItem ? this.state.selectedItem._id : null
      } else {
        id = []
        this.state.users.map((user) => {
          id.push(user._id)
        })
      }
      if (!id) {
        return
      }
      this.setState({
        inProcess: true,
        errMsg: null
      })

    this.user = Controller.get().userMgr()
    let from = moment(this.responseByKey.fromDate).utc().valueOf()
    let to = moment(this.responseByKey.toDate).utc().valueOf()
    const options = {
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'audit_trail'], { getAuditInfo: true, orgId: this.orgId, userId: id, fromDate: from, toDate:to }),
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getString(err),
          inProcess: false,
        })
        return
      } else {

        if (data.auditList.length == 0) {
          this.setState({
            errMsg: Helper.getErrorMsg("No audit trail found"),
            inProcess: false,
          })
        } else {
          FileDownloader.prepExcelDownloadForAudit(data.auditList, ['Email','Time', 'Activity', 'Message', 'Details', 'data'], 'audit', 'Time', ['statusOfReview', 'camName', 'placeName', 'username', 'priorityChangedTo', 'eventTitle', 'eventTime'], (this.responseByKey.timezone ? this.responseByKey.timezone : AppConfig.DEFAULT_ZONEINFO))

          this.resetToDefaultValues()
          if (this.orgView) {
            this.fetchItemsForOrgView()
          } else {
            this.fetchItems();
          }
        }
      }
    }.bind(this))
    }

  }

  verifyUser = (callback) => {
    this.setState({
      inProcess: true,
      errMsg: null
    })

    ApiHelper.call(
      {
        method: 'POST',
        jsonBody: {
          orgId: this.orgId,
          email: this.responseByKey.email,
          action: 'lookup'
        },
        endPoint: ApiHelper.makeUrlPath(['users'])
      },
      function (err, result) {
        if (err) {
          this.setState({
            errMsg: Helper.getErrorMsg(err),
            inProcess: false,
          })
          if (callback) callback(Helper.getErrorMsg(err))
          return
        }

        this.setState({
          inProcess: false
        })
        if (!result || !result._id) {
          this.setUser()
        } else {
          this.responseByKey = result
          this.getOrgSpecificsOrgId()
          return callback(null, result)
        }

      }.bind(this))
  }

  setUser = (item) => {
    if (item) {
      this.setState({
        selectedItem: item,
        verifyMemberDialog: false,
        updateMemberDialog: true,
        dialogAction: 'edit',
        dialogTitle: 'Edit a Member',
      })
    } else {
      this.setState({
        selectedItem: item,
        verifyMemberDialog: false,
        updateMemberDialog: true,
        dialogAction: 'add',
        dialogTitle: 'Add a Member',
      })
    }
  }

  verifyUserSuccess = (responseByKey) => {
    this.responseByKey = responseByKey
    this.verifyUser((err, result) => {
      this.setUser(result)
    })
  }

  renderVerifyUserDialog = () => {
    const formFields = [appData.userFieldEmail.email]
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.verifyMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={false}
        errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}
        action={'lookup'}
        formFields={formFields}
        responseByKey={this.responseByKey}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        onSuccess={this.verifyUserSuccess}
        formDisabled={this.state.dialogAction === 'delete' ? true : false}
      >
      </CustomFormFieldsDialogV2>
    )
  }

  renderMemberDialog = () => {
    this.userFields = []
    if (this.orgId === 'PRODUCT') {
      this.userFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.adminUserFields || appData.adminUserFields) : appData.adminUserFields
      this.auditFields = appData.adminAuditFieldConfig
    } else if (this.org) {
      let typeFound = false
      for (const f of AppConfig.orgTypes) {
        if (f.key === this.org.type) {
          typeFound = true
          if (f.userFieldConfig) {
            this.userFields = f.userFieldConfig
          } else {
            this.userFields = appData.userFields
          }
          if (f.auditFieldConfig) {
            this.auditFields = f.auditFieldConfig
          }
          break
        }
      }

      if (!typeFound) {
        this.userFields = appData.userFields
      }

      for (const uField of this.userFields) {
        if (uField.key === 'placeIdsEnabled') {
          const options = []
          for (const p of this.state.places) {
            options.push({
              name: p.name,
              key: p._id
            })
          }
          uField.options = options
        }
      }
    }

    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.updateMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteMember()}
        downloadAuditTrail={() => this.downloadAuditTrail()}

        action={this.state.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.addMember}
        formFields={this.state.auditTrailEnabled ? this.auditFields : this.userFields}
        auditTrailEnabled={this.state.auditTrailEnabled}

        errMsg={this.state.errMsg}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        formDisabled={this.state.dialogAction === 'delete' ? true : false}>
      </CustomFormFieldsDialogV2>
    )
  }

  showAddOwnVehicleDialog = () => {
    this.setState({
      addOwnDialog: true,
      dialogTitle: 'Add Own Vehicle',
      dialogAction: 'add',
    })
  }

  showAddGuestVehicleDialog = () => {
    this.setState({
      addGuestVehicleDialog: true,
      dialogTitle: 'Add Guest Vehicle',
      dialogAction: 'add',
    })
  }

  onExcelValidationSuccess = (data) => {
    //console.log('onValidationSuccess', data)
    this.addMembers(data)
  }

  renderBulkUserUploadDialog = () => {
    return (
      <CustomDialog
        onClose={this.resetToDefaultValues}
        disableBackdropClick={true}
        dialogOpen={this.state.bulkUserUploadDialog}
        dialogTitle={this.state.dialogTitle}
        inProcess={this.state.inProcess}>
        <div className={css(Styles.excelTipsOuterContainer)}>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>
              Please upload the file only in the specified format. You can download a sample file
              <a className={css(Styles.downloadLink)} href='https://uvsandbox-azuv-assets.s3.ap-south-1.amazonaws.com/import-users.xlsx'> here.</a> </p>
          </div>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>Uploading file in any other format will lead to failure of upload.</p>
          </div>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>For mobile entries, dial code should be given in the exact format as specified in our sample file</p>
          </div>

          <ExcelDocUploader
            uploadId={'dataList'}
            uploadType="Users"
            onExcelValidationSuccess={this.onExcelValidationSuccess}
          />

          {this.state.errMsg &&
            <div className={css(Styles.excelTipsContainer)}>
                <Icon fontSize={'small'} className={css(Styles.excelErrorIcon)}>error</Icon>
                <p className={css(Styles.excelErrorMessage)}>{this.state.errMsg}</p>
            </div>
          }

        </div>

      </CustomDialog>
    )
  }

  onRowItemClick = () => {
    return
  }

  onSettingClick = (e) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    Controller.get().pushRoute('/orgs/' + this.orgId + '/settings')
  }


  showStatsHandler = (value) => {
    let orgValue = null
    if (value) {
      orgValue = 'ON'
    } else {
      orgValue = 'OFF'
    }
    this.setState({ showStats: value }, () => Controller.get().userMgr().setUserSettings({ orgStat: orgValue }))
  }

  openDialog = (type , func) => {
    console.log(type, func)
    if(func === 'edit') {
      this.setState({
        dialogAction: 'edit',
        dialogTitle: "Edit Vehicle",
      });
      if (type === "guest") {
        this.setState({
          addGuestVehicleDialog: true,
        });
      } else {
        this.setState({
          addOwnDialog: true,
        });
      }
    }

    if(func === 'delete') {
      this.setState({
        dialogAction: "delete",
        dialogTitle: "Delete Vehicle",
      });
      if (type === "guest") {
        this.setState({
          addGuestVehicleDialog: true,
        });
      } else {
        this.setState({
          addOwnDialog: true,
        });
      }
    }
  }
  
  closeDialog = () => {
    this.setState({
      addGuestVehicleDialog: false,
      addOwnDialog: false,
      dialogAction: "",
      dialogTitle: "",
    })
  }

  showDeleteVehicleDialog = () => {
    this.responseByKey = this.state.selectedItem;
    const thisObj = this;
    thisObj.setState({
      anchorEl: null,
      editOwnDialog: true,
      dialogTitle: "Delete Vehicle",
      dialogAction: "delete",
    });
  }

  render() {
    
    if (sessionStorage.getItem('_mwl') == '6479eae5-d5a9-4931-9206-38237e06d067') {
      sessionStorage.setItem('_mwl', 'b582e928-3c56-4b3d-a4da-5335faa5ca25')
      Controller.get().loginMgr().signOut()
    }

    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let orgName = null
    if (this.org && this.org.name === 'PRODUCT') {
      orgName = AppConfig.NAME
    } else if (this.org) {
      orgName = this.org.name
    }

    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={(this.orgView && Controller.get().userMgr().isProductUser()) ? '/orgs' : null}
          title={orgName}
          items={this.state.users}
          org={this.org}
          showSettingsIcon={true}
          buttonOneAction={this.showAddMemberDialog}
          auditButtonTitleAction = {()=>this.showAuditDialog(true)}
          auditButtonTitle = {'AUDIT'}
          buttonOneTitle={'ADD NEW MEMBER'}
          onSettingClick={this.onSettingClick}
          buttonTwoAction={this.showAddOwnVehicleDialog}
          buttonTwoTitle={this.org.type && this.org.type.toLowerCase() == 'society' ? 'ADD OWN VEHICLE':''}
          buttonThreeAction={this.showAddGuestVehicleDialog}
          buttonThreeTitle={this.org.type && this.org.type.toLowerCase() == 'society' ? 'ADD GUEST VEHICLE':''}
          buttonFourTitle={this.org.type && this.org.type.toLowerCase() == 'society' ?'Upload from excel':''}
          buttonFourAction={this.showBulkUserUploadDialog}
          inProcess={this.state.inProcess}
          showStats={this.state.showStats}
          showSummaryButton={this.org && this.org.attributes && Object.keys(this.org.attributes).length !== 0 ? true : false}
          showStatsHandler={this.showStatsHandler}
        />

        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
            {this.org && this.org.attributes && Object.keys(this.org.attributes).length > 0 &&
              <StatsComponent
                mode={'org'}
                entityId={this.orgId}
                parentAttributes={this.parentAttributes}
                attributes={this.org.attributes}
                showStats={this.state.showStats}
                showStatsHandler={this.showStatsHandler}
                state={this.state}
                orgLevel={true}
                refresh={this.fetchItemsForOrgView}
              />
            }

            {this.state.showStats &&
              <div className={css(Styles.divider)}></div>
            }

            {this.orgView &&
              <Stats
                org={this.org}
                places={this.places}
                cams={this.cams}
                users={this.state.users}
              />
            }

            {this.state.vehicles && this.org.type && this.org.type.toLowerCase() == 'society' &&
              <Vehicle
               vehicles={this.state.vehicles}
               showGuestVehicleDialog={this.state.addGuestVehicleDialog}
               showOwnVehicleDialog={this.state.addOwnDialog}
               dialogTitle={this.state.dialogTitle}
               dialogAction={this.state.dialogAction}
               openDialog={this.openDialog}
               closeDialog={this.closeDialog}
               fetchItems={this.fetchItems}
               fetchItemsForOrgView={this.fetchItemsForOrgView}
               orgId={this.orgId}
               orgView={this.orgView}
              />
            }

            {this.state.users &&
              <TableListV2
                disableRowClick={true}
                onRowItemClick={this.onRowItemClick}
                tableColumnTitles={window.innerWidth < 767 ? appData.userColMobileTitles : appData.userColDesktopViewTitles}
                tableColumnTitlesData={window.innerWidth < 767 ? appData.userColTitles : appData.userColDesktopViewTitles}
                items={this.state.users}
                editClick={this.editClick}
                id={id}
              />
            }

            {this.state.users.length === 0 &&
              <p className={css(CommonStyles.emptyView)}>{Helper.getString('noUsers')}</p>
            }
          </div>
        </div>

        {this.renderVerifyUserDialog()}

        {this.renderMemberDialog()}


        <PopOverV2
          id={id}
          open={open}
          items={AppConfig.allowAuditConfig? appData.auditPopOverFields : appData.popOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />

        {this.state.bulkUserUploadDialog &&
          this.renderBulkUserUploadDialog()
        }
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px'
  },
  excelTips: {
    margin: 0,
    color: AppTheme.white,
  },
  excelTipsIcon: {
    marginRight: 5,
    color: AppTheme.white,
  },
  excelErrorIcon: {
    marginRight: 5,
    color: 'red',
  },
  excelErrorMessage: {
    margin: 0,
    color: 'red',
  },
  excelTipsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10
  },
  excelTipsOuterContainer: {
    marginBottom: 10
  },
  downloadLink: {
    color: 'orange'
  },
  divider: {
    height: 20
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
  }
})