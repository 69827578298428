import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import { Controller } from '../Controller';
import { Helper } from '../helpers/Helper';
import { AppConfig } from '../../AppConfig';
import { ColourPalette } from '../../styles/AppColourPalette';

export class NoPermission extends Component {
  render() {
    return (
      <div className={css(Styles.nfMiddle)}>
        <p>{Helper.getString('permissionErr')}</p>
        <div>
          <Button className={css(Styles.button)}
            onClick={this.goToHome.bind(this)}>
            {Helper.getString('logout')}
          </Button>
        </div>
      </div>
    )
  }
  goToHome() {
    if (AppConfig.allowLocal) {
      Controller.get().userMgr().signOut()
    } else {
      Controller.get().logout()
    }
  }
}


const Styles = StyleSheet.create({
  nfMiddle: {
    maxWidth: 500,
    fontSize: 20,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  button: {
    textAlign: 'center',
    backgroundColor: ColourPalette.color3,
    fontSize: 16,
    borderRadius: 4,
    cursor: 'pointer',
    padding: 10,
    color: 'black'
  },
})
