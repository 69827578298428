/* eslint-disable */ 
import { StyleSheet } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';

export const CommonStyles = StyleSheet.create({
  textfulPageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    textAlign: 'left',
    margin: 20,
    maxWidth: 900,
    paddingBottom: 100
  },
  textfulPageSubtitle: {
    fontSize: AppTheme.fontSizeTitle
  },
  textfulPagePara: {
    fontSize: AppTheme.fontSizeContent
  },
  outerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  outerHeaderContainer: {
    backgroundColor: AppTheme.primaryColor,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 35,
    paddingTop: 15,
    paddingBottom: 15,
    top: '6.9%',

    '@media(max-width: 768px)': {
      minHeight: 'unset',
    },
    '@media(max-height: 900px)': {
      top: '10%',
    },
    position: 'fixed',
    width: '100%',
    zIndex: '10',

  },
  fixedHeaderTop:{
    top: '0%',
  },
  outerHeaderContainerNextRow: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 35,
    paddingTop: 15,
    paddingBottom: 15,
    '@media(max-width: 768px)': {
      minHeight: 'unset',
    }
  },
  outerMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '92vh'
  },
  innerHeaderContainer: {
    width: '90%',
    display: 'flex',
    height: AppTheme.actionHeaderbarHeight,
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      height: 'unset',
    }
  },
  innerHeaderContainerNextRow: {
    width: '90%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: AppTheme.actionHeaderbarHeight,
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      height: 'unset',
    }
  },
  innerHeaderContainerCustom: {
    '@media(max-width: 768px)': {
      flexDirection: 'column',
      height: 'unset',
    }
  },
  innerHeaderFirstContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      marginBottom: 10
    }
  },
  marginLeft: {
    marginLeft: 10
  },
  fillSpace: {
    flex: 1
  },
  menuItemPopoverContainer: {
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: 4,
    minWidth: 100,
  },
  menuItemContainer: {
    color: AppTheme.white,
    padding: 5,
    margin: 5,
    borderRadius: 4,
    minHeight: 35,
    ':hover': {
      backgroundColor: ColourPalette.color1,
      color: AppTheme.primaryBackground
    }
  },
  menuItemPopoverContent: {
    fontSize: AppTheme.fontSizeContent
  },
  paragraphMargin: {
    margin: 0,
    cursor: 'default',
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  margin: {
    margin: 0
  },
  menuItemContainerRoot: {
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: 4,
    marginTop: 5
  },
  menuItemListContainerRoot: {
    padding: 0
  },
  innerMainContainer: {
    backgroundColor: AppTheme.secondaryColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7%',
  },
  mgTop2:{
    marginTop: '2%',
  },
  tableRowContainer: {
    flex: 1,
    '@media(max-width: 767px)': {
      marginRight: 5
    }
  },
  tableRowContainerVariant: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 767px)': {
      marginRight: 5
    }
  },
  tableInnerContent: {
    flex: 1,
    color: AppTheme.white,
    fontSize: AppTheme.fontSizeTitle,
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media(max-width: 767px)': {
      alignItems: 'flex-start',
      marginRight: 5,
      marginBottom: 5,
    }
  },
  innerTableEditContent: {
    flex: .5,
    color: AppTheme.white,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  innerTableHeaderContainer: {
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: '4px 4px 0 0',
    padding: '20px 25px',
    marginTop: 20,
    display: 'flex',
    '@media(max-width: 767px)': {
      padding: '20px 10px',
    }
  },
  innerTableHeaderContent: {
    flex: 1,
    color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    display: 'flex',
    alignItems: 'center',
    opacity: 0.6,
    fontWeight: 'bold',
    '@media(max-width: 767px)': {
      marginRight: 5
    }
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  emptyContent: {
    color: AppTheme.white,
    display: 'flex',
    alignItems: 'center'
  },
  innerTableHeaderSortContent: {
    flex: .5,
    color: AppTheme.white,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  headerOrgOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      //marginTop: 10,
    }
  },
  headerMemberCountContainer: {
    color: AppTheme.white,
    margin: 0,
    marginRight: 20,
    cursor: 'default',
    fontSize: 18,
    '@media (max-width: 767px)': {
      fontSize: 12,
    }
  },
  headerMemberCountContainerSpec: {
    flex: 1,
  },
  headerMemberOuterContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      justifyContent: 'space-between',
    }
  },
  headerMemberAddContainer: {
    opacity: 0.8,
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
    backgroundColor: AppTheme.secondaryColor,
    display: 'flex',
    alignItems: 'center',
    color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    cursor: 'pointer',
    fontWeight: 'bolder',
    justifyContent: 'space-around',
    border: '1px solid ' + AppTheme.secondaryColor,
    height: AppTheme.actionHeaderbarHeight,
    ':hover': {
      border: '1px solid ' + ColourPalette.color3
    }
  },
  headerMemberStyle: {
    marginLeft: 0,
    marginRight: 20,
  },
  headerMemberOuterMobileStyle: {
    '@media(max-width: 767px)': {
      marginTop: 10
    }
  },
  headerMemberMobileStyle: {
    '@media(max-width: 768px)': {
      display: 'none'
    }
  },
  addIcon: {
    color: AppTheme.white,
    marginRight: 10
  },
  backButton: {
    marginLeft: 0,
    marginRight: 10,
  },
  addImg: {
    color: AppTheme.white,
    marginRight: 10
  },
  headerMemberAddContainerCam: {
    padding: 0,
    border: '0px solid ' + AppTheme.secondaryColor,
    ':hover': {
      border: '0px solid ' + ColourPalette.color3
    }
  },
  emptyView: {
    color: 'white',
    marginTop: 200,
    textAlign: 'center',
    flex: 1
  },
  statsComponentContainer: {
    width: '90%',
    '@media(max-width: 767px)': {
      width: '100%',
    }
  },
});