import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';

export class NotFound extends Component {
  render() {
    return (
      <div className={css(Styles.container)}>
        <p className={css(Styles.nfMiddle)}>Unknown page. Check the link and try again.</p>
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nfMiddle: {
    padding: 10,
    textAlign: 'center',
    color: 'white'
  }
})