import React, { Component } from 'react';
import {
  css,
  StyleSheet
} from 'aphrodite';
import { FormFields } from './FormFields';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { styled } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';

export class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.formFields = {}
    this.responseByKey = this.props.item.settings || {}
    this.state = {
      inProcess: false,
      // checked :this.responseByKey["overwriteagents"] && this.responseByKey["overwriteagents"]['enabled'] ? this.responseByKey["overwriteagents"]['enabled'] : false,
      checked: false,
      openOverWriteAgentsModal: false,
    }
  }

  componentDidMount() {}

  setFormFields() {
    if(this.props.showOffice){	
      for (const fKey in this.props.data.officeSettings) {	
        this.formFields[fKey] = {	
          fields: this.props.data.officeSettings[fKey].fields	
        }	
      }	
    }else{
    for (const fKey in this.props.data.features) {
      this.formFields[fKey] = {
        fields: this.props.data.features[fKey].fields
      }
    }
  }
 }

  setValue = (value, key, parentKey) => {
    if (this.responseByKey[parentKey]) {
      this.responseByKey[parentKey][key] = value
    } else {
      this.responseByKey[parentKey] = {
        [key]: value
      }
    }
    this.setState({})
  }

  onSubmit = () => {
    this.validateFieldsAndSubmit()
  }

  validateFieldsAndSubmit() {
    this.setState({
      respMsg: null
    })
    let valid = true
    for (const fKey in this.formFields) {
      for (const f of this.formFields[fKey].fields) {
        if (this.responseByKey[fKey]) {
          const va = ValidationHelper.validateFieldsAndFormat(f, this.responseByKey[fKey][f.key])
          if (va.err) {
            valid = false
            this.setState({
              respMsg: va.err
            })
          } else {
            this.responseByKey[fKey][f.key] = va.valueFormatted
          }
        }
      }
    }

    if (!valid) {
      return
    }

    this.setState({
      inProcess: true,
    })
    let body = {
      [this.props.apiBodyPrefix]: {
        settings: this.responseByKey
      }
    }
    
    if(this.props.showOffice){
      body = {
        [this.props.apiBodyPrefix]: {
          officeSettings:{
          settings: this.responseByKey
          }
        }
      }
    }
    const options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath([this.props.itemName, this.props.itemId]),
      // jsonBody: {
      //   [this.props.apiBodyPrefix]: {
      //     settings: this.responseByKey
      //   }
      // }
      jsonBody: body
    }

    Helper.removeNullValues(options.jsonBody[this.props.apiBodyPrefix])

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          respMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      }
      this.setState({
        respMsg: Helper.getString('settingsUpdated'),
        inProcess: false,
      })
    }.bind(this))
  }

  getTitle = (key) => {
    if (key === 'settings' && this.props.data.settings && this.props.data.settings._title) {
      return this.props.data.settings._title.toUpperCase()
    } else if(this.props.data.features[key]){
      return this.props.data.features[key]._title.toUpperCase()
    }else{
      return this.props.data.officeSettings[key]._title.toUpperCase()
    }
  }

  renderModal = () => {
    return (
      <CustomFormFieldsDialogV2
        onClose={() => this.onClose()}
        dialogOpen={this.state.openOverWriteAgentsModal}
        dialogTitle={"Confirm"}
        confirmOverwriteView={true}
        confirmOverWrite={this.confirmOverWrite}
      >
      </CustomFormFieldsDialogV2>
    )
  }

  componentDidMount() {

    if(!this.props.camSettingClass){
    this.setValue(false, "enabled", "overwriteagents")
  }
  }

  confirmOverWrite = () => {
    this.setState({
      openOverWriteAgentsModal: false
    })
    this.setValue(true, "enabled", "overwriteagents")
  }

  onClose = () => {
    this.setState({
      openOverWriteAgentsModal: false,
      checked: false
    })
    this.setValue(false, "enabled", "overwriteagents")
  }

  handleChange = (event) => {
    this.setState({
      openOverWriteAgentsModal: !this.state.checked ? true : false,
      checked: event.target.checked
    })
    this.setValue(event.target.checked, "enabled", "overwriteagents")
  };

  render() {
    this.setFormFields()
    return (
      <div className={css(Styles.innerContainer)}>

        {(Object.keys(this.formFields) && Object.keys(this.formFields).length > 0) &&
          Object.keys(this.formFields).map((key, index) => {

            return (
              key == "overwriteagents" ?
                <>
                  <FormControlLabel
                    className={css(Styles.checkboxColorSecondary)}
                    control={
                      <Checkbox
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        parentKey={key}
                        responseByKey={this.responseByKey[key]}
                        className={css(Styles.checkboxColorSecondary)}
                      />
                    }
                    label={this.getTitle(key)}
                  />{this.renderModal()}
                </>
                :
                <div className={css(Styles.formFieldsOuterContainer, key == "lock" ? (this.props.camSettingClass ? Styles.noMarging : "") : "")} >
                  {this.getTitle(key) ?
                    <h4 className={css(Styles.formFieldMainLabel, key == "lock" ? Styles.noBackGround : "")}>{this.getTitle(key)}
                    </h4>
                    : ""}
                  <FormFields
                    enableValidation={true}
                    parentKey={key}
                    responseByKey={this.responseByKey[key]}
                    formFields={this.formFields[key].fields}
                    setValue={this.setValue}
                  />
                </div>
            )
          })
        }

        <div className={css(Styles.dialogActionsInnerContainer)}>
          {this.state.respMsg &&
            <p className={css(Styles.deleteMsg)}>{this.state.respMsg}</p>
          }

          <div className={css(Styles.dialogActionsInnerContainerButton)}>
            {
              <Button
                disabled={this.state.inProcess}
                variant='contained'
                onClick={() => this.onSubmit()}
                className={css(Styles.buttonStyle)}>
                SAVE
                {this.state.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  formFieldsOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0px 30px 0px',
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: 10,
    overflow: 'hidden'
  },
  formFieldMainLabel: {
    color: AppTheme.white,
    backgroundColor: AppTheme.secondaryColorVariant,
    margin: 0,
    padding: '20px 0px 20px 10px'
  },
  dialogActionsInnerContainer: {
    width: '100%',
    display: 'flex',
  },
  dialogActionsInnerContainerButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteMsg: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    fontWeight: 'bold',
    color: AppTheme.white,
    fontSize: 15,
    cursor: 'defalut',
    marginLeft: 8
  },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  circulerProgress: {
    marginLeft: 10,
    color: AppTheme.white
  },
  noBackGround: {
    backgroundColor: 'unset !important'
  },
  noMarging: {
    margin: '-50px 0px 30px 0px !important',
  },
  checkboxColorSecondary: {
    color: AppTheme.white,
  },
  fs20: {
    fontSize: '20px !important'
  }
})