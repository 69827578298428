/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../../styles/AppTheme';

const ERROR_MSG = 'We are experiencing a technical issue. Contact customer care team if problem persists.'

export class UiHelper {
  static loadingView() {
    return (
      <div className='loader'></div>
    )
  }

  static errorView(caller) {
    return (
      <div className={css(Styles.pageError)}>
        {caller.state.errMsg || ERROR_MSG}
      </div>
    )
  }

  static componentLoadingView() {
    return (
      <div className={css(Styles.container)}>
        <CircularProgress size={48} className={css(Styles.progress)} />
      </div>
    )
  }

  static componentErrorView(caller) {
    return (
      <div className={css(Styles.container)}>
        <p className={css(Styles.error)}>{caller.state.errMsg || ERROR_MSG}</p>
      </div>
    )
  }

  static getErrorMsg = () => {
    return (
      <div className={css(Styles.errMsgContainer)}>
        <p className={css(Styles.errMsg)}>Your permissions does not allow accessing this page. Contact customer care team if you did not expect this.</p>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    height: '100vh',
  },
  progress: {
    color: AppTheme.white
  },
  error: {
    textAlign: 'center',
    maxWidth: 500
  },
  pageError: {
    padding: 10,
    textAlign: 'center',
    maxWidth: 500,
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    margin: 'auto',
    color: AppTheme.white,
    '@media(max-width: 767px)': {
      fontSize: 15,
      textAlign: 'center'
    }
  },
  errMsgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: AppTheme.white
  }
})