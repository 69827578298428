/* eslint-disable */ 
import { AppConfig } from '../../AppConfig';
import { ApiHelper } from '../helpers/ApiHelper';
import { Controller } from '../Controller';
const CryptoJS = require('crypto-js');


export class UserMgr {
  userFb = null;
  userLocal = null;
  appUser = null;
  userSettings = null;
  defaultUserSettings = AppConfig.userDefaultSettings;
  currUserSettings = null;
  tkn = null;
  currentOrg = null;

  constructor() {
    this.setAppUser();
  }

  encrypt(data) {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    const encryptedData = CryptoJS.AES.encrypt(data, AppConfig.frontendSecretKey).toString();
    return encryptedData;
  }

   decrypt(encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, AppConfig.frontendSecretKey).toString(CryptoJS.enc.Utf8);
    const jsonData = JSON.parse(decryptedData);
    return jsonData;
  }

  updateUserSettings(userSettings) {
    if (!this.currentOrg) {
      return
    }
    const orgId = this.currentOrg ? this.currentOrg._id : null
    const options = {
      endPoint: ApiHelper.makeUrlPath(['users', this.appUser._id]),
      jsonBody: {
        action: 'UPDATE_SETTINGS',
        orgId: orgId,
        settings: userSettings
      },
      method: 'PUT'
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        const msg = err.message ? err.message : 'An error occurred, please try again. Contact customer care if problem persists.';
        console.log('err', err)
        return
      } else {
        this.currUserSettings = userSettings
      }
    }.bind(this))
  }

  updateWithDefaultSettings(settings) {
    if (settings) {
      if (settings.ALERT_EMAIL === undefined) {
        settings.ALERT_EMAIL = this.defaultUserSettings.ALERT_EMAIL;
      }
      if (settings.EVENT_VISIBILITY === undefined) {
        settings.EVENT_VISIBILITY = this.defaultUserSettings.EVENT_VISIBILITY;
      }
      if (settings.EVENT_EMAIL_ALERT === undefined) {
        settings.EVENT_EMAIL_ALERT = this.defaultUserSettings.EVENT_EMAIL_ALERT;
      }
      if (settings.CAMERAS === undefined) {
        settings.CAMERAS = this.defaultUserSettings.CAMERAS;
      }
    } else {
      settings = this.defaultUserSettings
    }
    return settings
  }

  setFullScreenMode(fullscreenMode) {
    if (fullscreenMode) {
      localStorage.setItem('fullscreenMode', JSON.stringify('ON'))
    } else {
      localStorage.removeItem('fullscreenMode');
    }
  }

  setUserSettings(stat) {
    //console.log(stat, 'Stats')
    let settings = JSON.parse(localStorage.getItem('settings'))
    //console.log(settings, 'settings')
    if (!settings) {
      settings = { userSettings: stat }
      //console.log(settings, 'settings::1')
      localStorage.setItem('settings', JSON.stringify(settings))
      return
    }
    if (stat.placeStat) {
      settings.userSettings.placeStat = stat.placeStat
    } else if (stat.orgStat) {
      settings.userSettings.orgStat = stat.orgStat
    } else if (stat.camStat) {
      settings.userSettings.camStat = stat.camStat
    } else if (stat.eventRefresh) {
      settings.userSettings.eventRefresh = stat.eventRefresh
    }
    //console.log(settings, 'settings::2')
    localStorage.setItem('settings', JSON.stringify(settings))
  }

  getSettings() {
    const settings = JSON.parse(localStorage.getItem('settings'))
    if (settings) {
      return settings
    }
    return null;
  }

  getFullScreenMode() {
    const fullscreenMode = localStorage.getItem('fullscreenMode')
    if (fullscreenMode) {
      return fullscreenMode
    }
    return null;
  }
  setAppUserCloud(data) {
    this.appUser = data ? data.appUser : null;
    sessionStorage.setItem('appUser', this.encrypt(JSON.stringify(this.appUser)))
    if (this.appUser) {
      this.handleCurrentOrg(this.appUser)
    }

    let settings = {};

    if (data && data.userSettings && Object.keys(data.userSettings).length) {
      settings = data.userSettings;
      settings = this.updateWithDefaultSettings(settings)
      this.currUserSettings = settings;
      this.updateUserSettings(settings);
    }
    if (this.appUser) {
      this.setAppUser()
    }
  }

  setloggedInUserLocal(user) {
    return new Promise((resolve, reject) => {
      this.userLocal = {
        type: 'local',
        token: user.token
      }
      sessionStorage.setItem('userLocal', JSON.stringify(this.userLocal))
      let userEndpoint = ApiHelper.makeUrlPath(['users', 'me'])
      //TODO: required here ?
      if (this.currentOrg) {
        userEndpoint = ApiHelper.makeUrlPath(['users', 'me'], { orgId: this.currentOrg._id })
      }
      const that = this;

      ApiHelper.call({ method: 'GET', endPoint: userEndpoint }, function (err, data) {
        if (!err) {
          that.appUser = data.appUser;
          sessionStorage.setItem('appUser',this.encrypt(JSON.stringify(this.appUser)))
          that.handleCurrentOrg(that.appUser)

          let settings = {};

          if (data.userSettings && Object.keys(data.userSettings).length) {
            settings = data.userSettings;
          }

          settings = that.updateWithDefaultSettings(settings)
          that.currUserSettings = settings;
          that.updateUserSettings(settings);

          that.setAppUser()

          resolve(true);
        } else {
          reject(err);
        }
      });
    })
  }

  updateCurrentOrg(currentOrg) {
    sessionStorage.removeItem('currentOrg');
    sessionStorage.setItem('currentOrg', JSON.stringify(currentOrg))
    this.currentOrg = currentOrg
  }

  updateFilterSettings(saveFilters) {
    //console.log('updateFilterSettings', saveFilters)
    localStorage.setItem('saveFilters', JSON.stringify({ saveFilters: saveFilters }))
    this.saveFilters = saveFilters
  }

  getFilterSettings() {
    const saveFiltersStr = localStorage.getItem('saveFilters')
    if (saveFiltersStr) {
      const saveFiltersJson = JSON.parse(saveFiltersStr)
      this.saveFilters = saveFiltersJson.saveFilters
      return this.saveFilters
    } else {
      return null
    }
  }

  updatePriorityFilters = (priorityFilters) => {
    //console.log('priorityFilters', priorityFilters)
    localStorage.setItem('priorityFilters', JSON.stringify({ priorityFiltersData: priorityFilters }))
    this.priorityFilters = priorityFilters
  }

  updateTagFilters = (tagFilters) => {
    //console.log('updateFilterSettings', tagFilters)
    localStorage.setItem('tagFilters', JSON.stringify({ tagFiltersData: tagFilters }))
    this.tagFilters = tagFilters
  }

  getPriorityFilters() {
    if (!this.getFilterSettings()) {
      return []
    }
    const priorityFiltersStr = localStorage.getItem('priorityFilters')
    if (priorityFiltersStr) {
      const priorityFiltersJson = JSON.parse(priorityFiltersStr)
      this.priorityFilters = priorityFiltersJson.priorityFiltersData
      return this.priorityFilters
    } else {
      return []
    }
  }

  getTagFilters() {
    if (!this.getFilterSettings()) {
      return []
    }
    const tagFiltersStr = localStorage.getItem('tagFilters')
    if (tagFiltersStr) {
      const tagFiltersJson = JSON.parse(tagFiltersStr)
      this.tagFilters = tagFiltersJson.tagFiltersData
      return this.tagFilters
    } else {
      return []
    }
  }

  handleCurrentOrg(appUser) {
    const currentOrg = sessionStorage.getItem('currentOrg');
    if (!currentOrg &&
      !this.currentOrg &&
      appUser &&
      appUser.orgs &&
      appUser.orgs.length > 0) {
        sessionStorage.removeItem('currentOrg');
        sessionStorage.setItem('currentOrg', JSON.stringify(appUser.orgs[0]))
      this.currentOrg = appUser.orgs[0]
    } else if (currentOrg) {
      this.currentOrg = JSON.parse(currentOrg);
      let orgFound = false
      if (appUser.orgs) {
        for (const org of appUser.orgs) {
          if (org._id === this.currentOrg._id) {
            orgFound = true
            this.currentOrg = org;
            sessionStorage.removeItem('currentOrg');
            sessionStorage.setItem('currentOrg', JSON.stringify(org))
            break
          }
        }
      }
      if (!orgFound) {
        sessionStorage.removeItem('currentOrg');
        this.currentOrg = null
      }
    } else if (appUser && appUser.orgs && appUser.orgs.length > 0) {
      sessionStorage.setItem('currentOrg', JSON.stringify(appUser.orgs[0]))
      this.currentOrg = appUser.orgs[0]
    } else {
      sessionStorage.removeItem('currentOrg');
      this.currentOrg = null
    }
  }

  getUser() {
    return this.appUser;
  }

  setUserToken(jwtToken) {
    this.jwtToken = jwtToken;
  }

  getUserToken() {
    //console.log('getUserToken:', this.jwtToken ? true : false);
    return this.jwtToken;
  }

  setAppUser() {
    let appUser = sessionStorage.getItem('appUser');
    appUser = appUser ? this.decrypt(appUser) : appUser
    if (appUser && !this.appUser && typeof appUser === 'string') {
      this.appUser = JSON.parse(appUser);
    }
    //console.log('setAppUser', this.appUser)
    const currentOrg = sessionStorage.getItem('currentOrg');
    if (currentOrg && !this.currentOrg) {
      this.currentOrg = JSON.parse(currentOrg);
    }
  }

  getloggedInUser() {
    const user = Controller.get().loginMgr().getCurrentUser() || this.userLocal;
    if (!user) {
      const userLocal = sessionStorage.getItem('userLocal')
      if (userLocal) {
        this.userLocal = JSON.parse(userLocal);
        return this.userLocal;
      }
    }
    if (user) {
      //console.log("getloggedInUser type:", user.type||'fib');
    } else {
      //console.log("getloggedInUser:", user);
    }
    return user;
  }

  checkUserPermission(url, loc) {
    if (this.isProductUser()) {
      return true
    } else if (loc.path === '/orgs' || loc.path === '/orgs/') {
      if (this.isProductUser()) {
        return true
      } else {
        return false
      }
    } else if (this.isResidentUser()) {
      if (
        loc.path === "/orgs/:id/guests" ||
        loc.path === "/" ||
        loc.path === "/orgs/:id/places" ||
        loc.path.startsWith("/places")
      ) {
        return true;
      } else {
        return false;
      }
    } else if ((loc.path).startsWith('/orgs')) {
      const orgId = (loc && loc.params) ? loc.params.id : ''
      if (this.appUser && this.appUser.orgs && this.appUser.orgs.length) {
        for (const org of this.appUser.orgs) {
          if (org._id === orgId) return true
        }
      }
      return false
    }
    //TODO: for places urls, at this point we don't have org information regarding the particular place, so we allow all
    return true;
  }

  isProductUser() {
    if (this.appUser && this.appUser.orgs && this.appUser.orgs.length) {
      for (const org of this.appUser.orgs) {
        if (org._id === 'PRODUCT') {
          return true
        }
      }
    }
    return false;
  }

  isAdminUser() {
    if (this.currentOrg &&
      this.currentOrg.roles &&
      this.currentOrg.roles.length &&
      this.currentOrg.roles.indexOf('ADMIN') > -1) {
      return true
    }
    return false;
  }

  isResidentUser() {
    if (this.currentOrg &&
      this.currentOrg.roles &&
      this.currentOrg.roles.length &&
      this.currentOrg.roles.indexOf('RESIDENT') > -1) {
      return true
    }
    return false;
  }

  getOrgId() {
    if (this.currentOrg && this.currentOrg._id) {
      return this.currentOrg._id
    }
    return null;
  }

  getCurrentOrg() {
    return this.currentOrg
  }

  getUserSettings() {
    if (this.currUserSettings) {
      return this.currUserSettings;
    } else {
      return this.defaultUserSettings;
    }
  }

  getProductLogo() {
    if (this.currentOrg && this.currentOrg.logoUrl) {
      return this.currentOrg.logoUrl
    }
    return null;
  }

  signOut() {
    this.userFb = null;
    this.userLocal = null;
    sessionStorage.removeItem('userLocal')
    sessionStorage.removeItem('appUser');
    sessionStorage.removeItem('currentOrg');
    sessionStorage.removeItem('__previousUserName');
    sessionStorage.removeItem('_mwl');
    localStorage.removeItem('fullscreenMode');

    this.appUser = null;
    this.currentOrg = null;
    this.currUserSettings = null;
    Controller.get().pushRoute('/login');
  }

  navigateUserToHome() {
    const home = this.getUserHome();
    console.log('home', home)
    Controller.get().pushRoute(home);
  }


  getUserHome() {
    if (this.appUser && this.appUser.orgs && this.appUser.orgs.length) {
      if (this.appUser.orgs[0]['_id'] === 'PRODUCT') {
        return AppConfig.adminDashboard;
      } else if (this.appUser.orgs &&
        this.appUser.orgs[0].roles &&
        this.appUser.orgs[0].roles[0] === 'RESIDENT' &&
        this.currentOrg) {
        return '/orgs/' + this.currentOrg._id + '/guests'
      } else if (this.currentOrg) {
        return '/orgs/' + this.currentOrg._id + '/places'
      } else {
        return '/no-permission'
      }
    } else {
      return '/no-permission'
    }
  }

  getMyOrgId() {
    if (this.currentOrg && this.currentOrg) {
      return this.currentOrg._id;
    }
  }

  getUserId() {
    if (this.appUser) {
      return this.appUser._id;
    } else {
      return null;
    }
  }

  getloggedInUserTkn() {
    if (this.tkn) {
      return this.tkn;
    } else {
      return null;
    }
  }

  setloggedInUserTkn(tkn) {
    this.tkn = tkn;
  }

  setCurrentLoggedInUserName(userName) {
    return new Promise((resolve, reject) => {
      sessionStorage.setItem('__previousUserName', this.encrypt(userName))
    });
  }

  cacheLastLoggedInUserName() {
    return new Promise((resolve, reject) => {
      let prevUserName = sessionStorage.getItem('__previousUserName')
      prevUserName = prevUserName ? this.decrypt(prevUserName) : ""
    });
  }

  getLastLoggedInUserName() {
    //console.log('getLastLoggedInUserName');
    return this.previousUserName;
  }


  setGuestUser(state) {
    //console.log('setGuestUser');
    this.guestUser = state;
  }
}
