/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UiHelper } from '../common/helpers/UiHelper';
import { Helper } from '../common/helpers/Helper';
import { Controller } from '../common/Controller';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import { adminData } from '../data/AdminData.js'
import { AppHelper } from '../helpers/AppHelper'
import { IdleTimeout } from './IdleTimer'
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SearchTextField } from './../screens/ClientPlace/SearchTextField';
import CircularProgress from '@material-ui/core/CircularProgress';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.eventData = adminData.eventData;
    this.appUser = Controller.get().userMgr().getUser()
    this.currentUserSettings = Controller.get().userMgr().getUserSettings()
    this.orgId = Controller.get().userMgr().getOrgId()
    this.state = {
      formErrorMsg: null,
      anchorEl: null,
      settingDialog: false,
      orgDialog: false,
      eventVisibility: this.currentUserSettings.EVENT_VISIBILITY,
      eventPriority: this.currentUserSettings.EVENT_EMAIL_ALERT,
      emailNotification: this.currentUserSettings.ALERT_EMAIL,
      emailNotificationValue: this.currentUserSettings.ALERT_EMAIL === 'ON' ? true : false,
      camSelected: [],
      orgSwitcher: false,
      currentOrgId: Controller.get().userMgr().getOrgId(),
      currentOrg: Controller.get().userMgr().getCurrentOrg(),
      fetchState: ApiHelper.State.LOADING,
      selectedPlace: "",
      viewRecentSessions:false,
      incidentNo:"",
      loadingAnimation:false,
      incidentErrMsg:""
    }
    this.activeTab = 'Organisations'
    this.placeDropdownList = []
    this.formFields = {
      eventVisibility: {
        key: 'eventVisibility',
        title: 'EVENT VISIBILITY',
        label: 'Select Event Priority Level'
      },
      eventPriority: {
        key: 'eventPriority',
        title: 'EVENT PRIORITY LEVEL FOR EMAIL ALERTS',
        label: 'Select Event Priority Level'
      },
      organisation: {
        key: 'currentOrgId',
        title: '',
        label: 'organisation'
      }
    }
    this.tabFields = []
  }

  componentDidMount() {
    this.fetchCams()
  }

  getCamIds() {
    if (!this.currentUserSettings.CAMERAS || this.currentUserSettings.CAMERAS.length === 0) {
      this.setState({
        fetchState: ApiHelper.State.READY
      })
      return [];
    } else {
      const camIds = this.currentUserSettings.CAMERAS;
      const selectedCamIds = []
      for (const item of this.state.items) {
        if (item._id === 'all') {
          for (const cam of item.cams) {
            if ((camIds).indexOf(cam._id) > -1) {
              selectedCamIds.push(cam._id)
            }
          }
        }
      }
      this.setState({
        camSelected: selectedCamIds,
        fetchState: ApiHelper.State.READY
      })
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onsettingClick = () => {
    this.fetchCams();
    this.setState({ settingDialog: true, anchorEl: null });
  }

  dialogClose = () => {
    this.setState({ settingDialog: false, anchorEl: null, formErrorMsg: null })
  }

  closeRecentSessionsViewer = () => {
    this.setState({ viewRecentSessions: false, anchorEl: null })
  }
  fieldsContainerChange = (event, ev) => {
    if (ev.props.value == "selectAll") {
      if (this.state.camSelected.includes("selectAll")) {
        this.setState({ [event.target.name]: [] });
      } else {
        let arr = this.state.items.map((p) => {
          if (p._id == 'all') {
            let a = p.cams.map((e) => e._id)
            return a;
          }
        });
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (!element) {
            delete arr[index]
          }
        }
        arr = arr.flat();
        this.setState({ [event.target.name]: arr })
      }
    } else {
      if (this.state.camSelected.includes(ev.props.value)) {
        let idx = event.target.value.indexOf(ev.props.value) != -1 ? event.target.value.indexOf(ev.props.value) : undefined
        if (idx) {
          event.target.value.splice(idx, 1)
        }
        if (event.target.value.includes("selectAll")) {
          event.target.value.splice(event.target.value.indexOf("selectAll"), 1)
        }
      }
      this.setState({ [event.target.name]: event.target.value });
    }

  }

  convertArrayToCsv(items) {
    let camIdStr = '';
    let index = 0;
    if (items && items.length > 0) {
      for (const item of items) {
        camIdStr = camIdStr + item;
        if (index !== (items.length - 1)) {
          camIdStr = camIdStr + ','
        }
        index = index + 1;
      }
      return camIdStr
    } else {
      return camIdStr
    }
  }

  saveSetting = () => {
    this.setState({
      formErrorMsg: null
    })
    let options = '';
    if (this.state.emailNotification === 'ON' && this.state.camSelected.length === 0) {
      this.setState({
        formErrorMsg: 'You have enabled email notifications. Please select atleast one camera.'
      })
      return
    }
    options = {
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['users', this.appUser._id], { orgId: this.orgId }),
      jsonBody: {
        orgId: this.orgId,
        action: 'UPDATE_SETTINGS',
        settings: {
          ALERT_EMAIL: this.state.emailNotification,
          EVENT_VISIBILITY: String(this.state.eventVisibility),
          EVENT_EMAIL_ALERT: String(this.state.eventPriority),
          CAMERAS: this.state.camSelected
        }
      }
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        const msg = err.message ? err.message : 'An error occurred, please try again. Contact customer care if problem persists.';
        this.setState({
          errMsg: msg,
          errCode: err.respCode,
        })
        return
      }
      this.setState({
        settingDialog: false,
      })
      window.location.reload();
    }.bind(this))
  }

  generatePicker = (formField) => {
    return (
      <div className={css(Styles.fieldsContainer)}>
        <p className={css(Styles.fieldsContainerHeader)}>{formField.title}</p>
        <FormControl className={css(Styles.inputCss)}>
          <InputLabel
            classes={{
              root: css(Styles.inputLabelRoot),
              focused: css(Styles.inputLabelFocused)
            }}>
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              classes: {
                paper: css(CommonStyles.menuItemContainerRoot),
                list: css(CommonStyles.menuItemListContainerRoot)
              }
            }}
            value={this.state[formField.key]}
            onChange={this.fieldsContainerChange}
            classes={{
              selectMenu: css(Styles.selectMenu),
              icon: css(Styles.selectIcon),
            }}
            inputProps={{
              name: formField.key,
            }}
            input={
              <Input
                disableUnderline={true}
              />
            }
          >
            {this.eventData.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  className={css(CommonStyles.menuItemContainer)}
                  value={item.value}>
                  {item.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  toggleEmail = name => event => {
    this.setState({ [name]: event.target.checked });
    if (event.target.checked) {
      this.setState({ emailNotification: 'ON' })
    } else {
      this.setState({ emailNotification: 'OFF' })
    }
  };

  fetchCams(callback) {
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], { orgId: this.orgId }) },
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        if (callback) {
          callback(results)
          return;
        }
        if (results && results.length > 0) {
          let allCams = [];
          allCams.push({ name: 'Select All', key: 'selectAll', _id: 'selectAll' })
          for (const r of results) {
            if (r.cams && r.cams.length > 0) {
              allCams = allCams.concat(r.cams)
            }
          }
          results.push({
            _id: 'all',
            name: 'All',
            cams: allCams
          })
        }

        this.setState({
          initialCamId: results && results[0] && results[0].cams[0] ? results[0].cams[0]._id : "",
          initialPlaceId: results && results[0] ? results[0]._id : "",
          items: results,
        }, () => this.getCamIds())
      }.bind(this))
  }

  setplaceDropdownList = () => {
    return (this.state.items && this.state.items.map((list, i) => {
        return (
          <MenuItem key={i} component={Link} 
            to={'/places/' + list._id + '/?camId=' + list.cams[0]._id}
            className={css(CommonStyles.menuItemContainer)} 
            value={list._id}
             >
              {list.name}
          </MenuItem>
        )
      })
    )
  }

  renderDialog() {
    return (
      <Dialog
        onClose={this.dialogClose}
        open={this.state.settingDialog}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
      >
        <div className={css(Styles.dialogTitle)}>
          <p className={css(Styles.dialogTitleContent)}>Settings</p>
          <div className={css(Styles.closeIconContainer)} onClick={this.dialogClose}>
            <img src={require('../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
          </div>
        </div>
        {this.state.fetchState === ApiHelper.State.LOADING &&
          <div className={css(Styles.loadingView)}>
            {UiHelper.componentLoadingView()}
          </div>
        }
        {this.state.fetchState === ApiHelper.State.READY &&
          <div>
            <DialogContent className={css(Styles.dialogContent)}>
              {this.generatePicker(this.formFields.eventVisibility)}
              {this.generatePicker(this.formFields.eventPriority)}
              <div className={css(Styles.fieldsContainer)}>
                <p className={css(Styles.fieldsContainerHeader)}>EMAIL NOTIFICATIONS</p>
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        track: this.state.emailNotificationValue && css(Styles.switchControlLabeltrack),
                        checked: css(Styles.switchControlLabelChecked),
                      }}
                      checked={this.state.emailNotificationValue}
                      onChange={this.toggleEmail('emailNotificationValue')}
                      value='emailNotificationValue'
                    />
                  }
                  classes={{
                    label: css(Styles.formControlLabel),
                  }}
                  label={this.state.emailNotification}
                />

                {this.state.emailNotificationValue &&
                  <FormControl className={css(Styles.inputCss)}>
                    <InputLabel classes={{
                      root: css(Styles.inputLabelRoot),
                      focused: css(Styles.inputLabelFocused)
                    }}
                    >Select Cams</InputLabel>
                    <Select
                      disabled={!this.state.emailNotificationValue}
                      multiple
                      renderValue={selected => (
                        this.state.items &&
                        this.state.items.map(item => {
                          return (
                            item._id === 'all' &&
                            item.cams.map(cams => {
                              return (
                                selected.map(camSelect => {
                                  return (
                                    camSelect === cams._id &&
                                    [cams.name] + ', '
                                  )
                                }))
                            }))
                        })
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: css(CommonStyles.menuItemContainerRoot),
                          list: css(CommonStyles.menuItemListContainerRoot)
                        }
                      }}
                      value={this.state.camSelected}
                      onChange={this.fieldsContainerChange}
                      classes={{
                        selectMenu: css(Styles.selectMenu),
                        icon: css(Styles.selectIcon),
                      }}
                      inputProps={{
                        name: 'camSelected',
                      }}
                      input={
                        <Input
                          disableUnderline={true}
                        />
                      }
                    >
                      {this.state.items &&
                        this.state.items.map(item => {
                          return (
                            item._id === 'all' &&
                            item.cams.map(list => {
                              return (
                                <MenuItem
                                  className={css(CommonStyles.menuItemContainer)}
                                  value={list._id} key={list.name}>
                                  <Checkbox
                                    className={css(Styles.check)}
                                    checked={this.state.camSelected.indexOf(list._id) > -1} />
                                  <ListItemText primary={list.name} />
                                </MenuItem>
                              )
                            })
                          )
                        })}
                    </Select>
                  </FormControl>
                }
              </div>
            </DialogContent>
            <DialogActions className={css(Styles.dialogActions)}>
              <Button
                variant='contained'
                onClick={() => this.saveSetting()}
                className={css(Styles.actionButton)}>
                Save
              </Button>
              <p className={css(Styles.formErrorMsg)}>{this.state.formErrorMsg}</p>
            </DialogActions>
          </div>
        }
      </Dialog >
    );
  }

  isFullScreenMode() {
    if (this.fullScreenMode &&
      window.location &&
      window.location.pathname &&
      (window.location.pathname).indexOf('places') >= 0 &&
      (window.location.pathname).indexOf('cams') >= 0) {
      return true
    }
    return false
  }

  selectedTab = (value) => {
    this.activeTab = value;
  }

  getToUrl = (key) => {
    const urlKeys = {
      orgs: '/orgs',
      places: '/orgs/' + this.orgIdSlug + '/places',
      events: '/orgs/' + this.orgIdSlug + '/events',
      overview: '/orgs/' + this.orgIdSlug,
      settings: '/orgs/' + this.state.currentOrg._id + '/setting',
      team: '/orgs/' + this.state.currentOrg._id + '/users',
      reports: '/orgs/' + this.orgIdSlug + '/reports',
      devices: '/orgs/' + this.state.currentOrg._id + '/devices',
      all: '/places/' + this.state.initialPlaceId + '/?camId=' + AppHelper.getSelectAllOption().key,
      resident: '/orgs/' + this.orgId+ '/guests',
    }
    if(AppConfig.SHOW_VIOLATION){
      urlKeys['violations'] = '/orgs/' + this.orgIdSlug + '/violations'
    }
    return urlKeys[key]
  }
  handlePlaceChange = (item) => {
    this.setState({
      selectedPlace: item.target.value
    })

  }

  tabFunction = (field, key) => {
    return (
      <Link
        to={this.getToUrl(field.key)}
        className={css(Styles.link)}
        onClick={() => this.selectedTab(field.key)}
        key={key}
      >
        <p className={this.activeTab === field.key ?
          css(Styles.linkContainerTab) : css(Styles.linkContainer)}>{field.label}</p>
      </Link>
    )
  }

  closeOrgSwitcher = () => {
    this.setState({
      orgSwitcher: false,
      anchorEl: null,
      formErrorMsg: null
    })
  }

  showOrgSwitcher = () => {
    this.setState({
      orgSwitcher: true,
      anchorEl: null,
    })
  }

  showRecentSessionsViewer = () => {
    this.setState({
      viewRecentSessions: true
    })
  }

  submitOrg = () => {
    Controller.get().userMgr().updateCurrentOrg(this.state.currentOrg)
    if (this.state.currentOrg._id === 'PRODUCT') {
      window.location.pathname = '/orgs/'
    } else {
      window.location.pathname = '/orgs/' + this.state.currentOrg._id + '/places'
    }
  }

  onSelect = event => {
    for (const item of this.props.currentUser.orgs) {
      if (item._id === event.target.value) {
        this.setState({
          [event.target.name]: event.target.value,
          currentOrg: item,
        });
        break;
      }
    }
  };

  generatePickerOrg = (formField) => {
    return (
      <div className={css(Styles.fieldsContainer)}>
        <p className={css(Styles.fieldsContainerHeader)}>{formField.title}</p>
        <FormControl className={css(Styles.inputCss)}>
          <InputLabel
            classes={{
              root: css(Styles.inputLabelRoot),
              focused: css(Styles.inputLabelFocused)
            }}>
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              classes: {
                paper: css(CommonStyles.menuItemContainerRoot),
                list: css(CommonStyles.menuItemListContainerRoot)
              }
            }}
            value={this.state[formField.key]}
            onChange={this.onSelect}
            classes={{
              selectMenu: css(Styles.selectMenu),
              icon: css(Styles.selectIcon),
            }}
            inputProps={{
              name: formField.key,
            }}
            input={
              <Input
                disableUnderline={true}
              />
            }
          >
            {this.props.currentUser.orgs.map(item => {
              return (
                <MenuItem
                  className={css(CommonStyles.menuItemContainer)}
                  value={item._id}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  generateRecentSessionViewer = () => {
    let appUserData = sessionStorage.getItem('appUser')
    appUserData = appUserData ? Controller.get().userMgr().decrypt(appUserData) : appUserData
    if(appUserData && typeof appUserData == 'string' ){
      appUserData = JSON.parse(appUserData)
    }
    let recentSessions = appUserData && appUserData.recentSessions ? appUserData.recentSessions : []


    return (
      <div className={css(Styles.fieldsContainer)}>
        <TableContainer >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell className={css(Styles.inputLabelFocused)}>SL No.</TableCell>
                <TableCell className={css(Styles.inputLabelFocused)}>IP ADDRESS</TableCell>
                <TableCell className={css(Styles.inputLabelFocused)}>DATE-TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                recentSessions.reverse().map((session, i) => {
                  let s = JSON.parse(JSON.stringify(session))
                  let date = moment(s.sessionTimestamp ? s.sessionTimestamp : undefined).format(
                    "MMM DD, YYYY, h:mm:ss A"
                  ).toString()
                  return (
                    <TableRow className={css(Styles.inputLabelFocused)} key={i}>
                      <TableCell className={css(Styles.inputLabelFocused)}>{i+1} </TableCell>
                      <TableCell className={css(Styles.inputLabelFocused)}>{s.sourceIP}</TableCell>
                      <TableCell className={css(Styles.inputLabelFocused)}>{date}{i==0 ? "  (Last Login)":""}</TableCell>
                    </TableRow>
                  )

                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  renderOrgSwitcher = () => {
    return (
      <Dialog
        onClose={this.closeOrgSwitcher}
        open={this.state.orgSwitcher}
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
      >
        <div className={css(Styles.dialogTitle)}>
          <p className={css(Styles.dialogTitleContent)}>Select an Organisation</p>
          <div className={css(Styles.closeIconContainer)} onClick={() => this.closeOrgSwitcher()}>
            <img src={require('../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
          </div>
        </div>

        <DialogContent className={css(Styles.dialogContent)}>
          {this.generatePickerOrg(this.formFields.organisation)}
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          <Button
            variant='contained'
            onClick={() => this.submitOrg()}
            className={css(Styles.actionButton)}>
            Save
          </Button>
          <p className={css(Styles.formErrorMsg)}>{this.state.formErrorMsg}</p>
        </DialogActions>
      </Dialog>
    )
  }

  renderRecentSessions = () => {
    return (
      <Dialog
        onClose={this.closeRecentSessionsViewer}
        open={this.state.viewRecentSessions}
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
      >
        <div className={css(Styles.dialogTitle)}>
          <p className={css(Styles.dialogTitleContent)}>Recent Sessions</p>
          <div className={css(Styles.closeIconContainer)} onClick={() => this.closeRecentSessionsViewer()}>
            <img src={require('../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
          </div>
        </div>
        <DialogContent className={css(Styles.dialogContent)}>
          {this.generateRecentSessionViewer()}
        </DialogContent>
     
      </Dialog>
    )
  }

  setTabFields = () => {
    const pathArray = this.props.path.split('/')
    const filtered = pathArray.filter(function (value) {
      return value.length > 0
    });

    if (
      filtered.length === 3 &&
      filtered[0] === "orgs" &&
      this.state.currentOrg.roles.includes("RESIDENT")
    ) {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'resident'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's',
          key: 'places'
        }
      ]
    } else if (filtered.length === 2 && filtered[0] === 'orgs') {
      this.orgIdSlug = filtered[1]
      if (this.state.currentOrg && this.state.currentOrg._id === 'PRODUCT') {
        this.tabFields = [
          {
            label: 'Overview',
            key: 'overview'
          },
          {
            label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
            key: 'places'
          },
          {
            label: 'Reports',
            key: 'reports',
          }
        ]

        
        if (AppConfig.SHOW_DEVICES_INFO) {
          this.tabFields.push({
            label: 'Devices',
            key: 'devices'
          })
        }

        if (AppConfig.SHOW_VIOLATION) {
          this.tabFields.push(
            {
              label: 'Violations',
              key: 'violations',
            })
        }


        if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
          this.tabFields.push({
            label: 'Events',
            key: 'all'
          })
        }
        
      } else {
        this.tabFields = [
          {
            label: 'Overview',
            key: 'overview'
          },
          {
            label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
            key: 'places'
          },
          {
            label: 'Reports',
            key: 'reports',
          }
        ]
        if (AppConfig.SHOW_DEVICES_INFO) {
          this.tabFields.push({
            label: 'Devices',
            key: 'devices'
          })
        }


        if (AppConfig.SHOW_VIOLATION) {
          this.tabFields.push(
            {
              label: 'Violations',
              key: 'violations',
            })
        }

        if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
          this.tabFields.push({
            label: 'Events',
            key: 'all'
          })
        }

      }

      this.activeTab = 'overview'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'places' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        }
      ]

      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }

      if (AppConfig.SHOW_VIOLATION) {
        this.tabFields.push(
          {
            label: 'Violations',
            key: 'violations',
          })
      }


      this.activeTab = 'places'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'reports' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        }
      ]

      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }

      if (AppConfig.SHOW_VIOLATION) {
        this.tabFields.push(
          {
            label: 'Violations',
            key: 'violations',
          })
      }

      this.activeTab = 'reports'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'violations' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        },	
        {	
          label: 'Violations',	
          key: 'violations',	
        }
      ]

      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }

      this.activeTab = 'violations'
    } else if (filtered.length === 1 &&
      filtered[0] === 'orgs' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.tabFields = [
        {
          label: 'ORGS',
          key: 'orgs',
        },
        {
          label: 'ADMINS',
          key: 'team'
        }
      ]
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'DEVICES',
          key: 'devices'
        })

      }
      this.activeTab = 'orgs'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'users' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'ORGS',
          key: 'orgs',
        },
        {
          label: 'ADMINS',
          key: 'team'
        }
      ]
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'DEVICES',
          key: 'devices'
        })

      }
      this.activeTab = 'team'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'devices' &&
      this.state.currentOrg &&
      this.state.currentOrg._id === 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'ORGS',
          key: 'orgs',
        },
        {
          label: 'ADMINS',
          key: 'team'
        }
      ]
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'DEVICES',
          key: 'devices'
        })
      }

      this.activeTab = 'devices'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'places' &&
      this.state.currentOrg &&
      this.state.currentOrg._id !== 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        }
      ]

      if (AppConfig.SHOW_VIOLATION) {
        this.tabFields.push(
          {
            label: 'Violations',
            key: 'violations',
          })
      }
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'Devices',
          key: 'devices'
        })
      }
      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }
      this.activeTab = 'places'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'reports' &&
      this.state.currentOrg &&
      this.state.currentOrg._id !== 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        }
      ]

      if (AppConfig.SHOW_VIOLATION) {
        this.tabFields.push(
          {
            label: 'Violations',
            key: 'violations',
          })
      }
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'Devices',
          key: 'devices'
        })
      }
      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }
      this.activeTab = 'reports'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'violations' &&
      this.state.currentOrg &&
      this.state.currentOrg._id !== 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        },
        {
          label: 'Violations',
          key: 'violations',
        }
      ]
      this.activeTab = 'violations'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'devices' &&
      this.state.currentOrg &&
      this.state.currentOrg._id !== 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        },
        {
          label: 'Reports',
          key: 'reports',
        }
      ]

      if (AppConfig.SHOW_VIOLATION) {
        this.tabFields.push(
          {
            label: 'Violations',
            key: 'violations',
          })
      }
      if (AppConfig.SHOW_DEVICES_INFO) {
        this.tabFields.push({
          label: 'Devices',
          key: 'devices'
        })
      }
      if (AppConfig.SHOW_DIRECT_LINK_EVENT) {
        this.tabFields.push({
          label: 'Events',
          key: 'all'
        })
      }
      this.activeTab = 'devices'
    } else if (filtered.length === 3 &&
      filtered[0] === 'orgs' &&
      filtered[2] === 'users' &&
      this.state.currentOrg &&
      this.state.currentOrg._id !== 'PRODUCT') {
      this.orgIdSlug = filtered[1]
      this.tabFields = [
        {
          label: 'Overview',
          key: 'overview'
        },
        {
          label: AppHelper.getPlaceAltName(this.state.currentOrg) + 's', //TODO
          key: 'places'
        }
      ]
      this.activeTab = 'team'
    } else {
      this.tabFields = []
    }
  }

  renderLogo = () => {
    const logo = Controller.get().userMgr().getProductLogo();
    return (
      <div className={css(Styles.logoContainer)}>
        <Link to={'/'}>
          <div className={css(Styles.logoImageContainer)}>
            <img
              src={logo ? logo : require('../assets/images/logo.png')}
              alt='Logo'
              onError={(e) => {
                e.target.src = 'https://uv-misc-test.s3.ap-south-1.amazonaws.com/logo-img.png'
              }}
              className={css(Styles.logoImage)} />
          </div>
        </Link>
      </div>
    )
  }

  renderTabs = () => {
    this.setTabFields();
    return (
      <div className={css(Styles.headerContentContainer)}>
        <div className={css(Styles.linkContentContainer)}>
          {this.tabFields.map((field, index) => {
            return (
              this.tabFunction(field, index)
            )
          })}
        </div>
      </div>
    )
  }

  renderIdleTimer = () => {
    return (
      <IdleTimeout logout={() => this.props.onLogoutClick()}></IdleTimeout>
    )
  }

  renderEventSearch = () => {
    return (
      <div >
        <div className={css(Styles.searchContainer, Styles.incidentSearch)}>
          <SearchTextField
            allowOnEnterSearch={true}
            searchHandlerByEnter={(val) => {
              this.setState({
                incidentNo: val
              }, () => {
                this.searchHandler()
              })
            }}
            searchHandler={(val) => {
              this.setState({
                incidentNo: val
              })
            }}
            label={"Search Incident"} hideIcon={true} />
          {!this.state.loadingAnimation && <div
            onClick={(e) => this.searchHandler(e)}
            className={css(Styles.personIcon)}>
            <img src={require('../assets/images/search-img.svg')} />
          </div>
          }
          {this.state.loadingAnimation && <CircularProgress color={"#fff"} size={20} className={css(Styles.marginLeft)} />}
        </div>
        {<p className={css(Styles.incidentErrMessageContainer)}>{this.state.incidentErrMsg}</p>}
      </div>
    )
  }

  searchHandler = () =>{
      this.setState({loadingAnimation : true,incidentErrMsg:""})
      let params={incidentId:this.state.incidentNo}
      const options = {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["camevents"], params),
      };
  
      ApiHelper.call(
        options,
        function (err, result) {
          this.setState({loadingAnimation : false})

          if (err || result.status == 500) {
            // window.open('/events/'+result._id)
              this.setState({
                fetchState: ApiHelper.State.ERROR,
                incidentErrMsg: Helper.getString('noEventsFound'),
              })
             
            return;
          }

          if(result && result._id){
            window.open('/events/'+result._id)
          }
  
        }.bind(this)
      );
  
  }

  renderMenuPopover = () => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let appUserData = sessionStorage.getItem('appUser') 
    appUserData = appUserData ? JSON.parse(JSON.stringify(Controller.get().userMgr().decrypt(appUserData))) : appUserData
    if(appUserData && typeof appUserData == 'string' ){
      appUserData = JSON.parse(appUserData)
    }
    let recentSessions = appUserData && appUserData.recentSessions ? appUserData.recentSessions : []
    if (this.props.currentUser) {
      return (
        <div className={css(Styles.logoContainer)} style={{alignItems:"center"}}>
            {this.renderEventSearch()}
          <div
            aria-describedby={id}
            onClick={(e) => this.handleMenu(e)}
            className={css(Styles.personIcon)}>
            <img src={require('../assets/images/person-img.svg')} />
          </div>
          
          <Popover
            id={id}
            open={open}
            classes={{
              paper: css(CommonStyles.menuItemPopoverContainer, Styles.menuItemPopoverContainer)
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={this.handleClose}
          >
            <div className={css(Styles.menuHeaderContainer)}>
              <div className={css(Styles.menuHeaderUserInfo)}>
                <p className={css(Styles.menuHeaderEmail)}>{this.appUser.email}</p>
                {this.state.currentOrg && this.state.currentOrg.roles.map((role, index) => {
                  return (
                    <div className={css(Styles.menuHeaderRoleConatiner)} key={index}>
                      <strong className={css(Styles.menuHeaderRole)}>{role}</strong>
                    </div>
                  )
                })}
              </div>
              {this.state.currentOrg &&
                <p className={css(Styles.menuHeaderOrgTitle)}>{this.state.currentOrg.name}</p>
              }
            </div>
            <div className={css(Styles.menuContainer)}>
              <MenuItem
                onClick={() => this.showOrgSwitcher()}
                className={css(CommonStyles.menuItemContainer)}>
                <Icon className={css(Styles.icons)} color='secondary'>apartment</Icon>
                <p className={css(Styles.iconsText)}>{'Organisation'.toUpperCase()}</p>
              </MenuItem>
              {!Controller.get().userMgr().isProductUser() &&
                <MenuItem
                  onClick={() => this.onsettingClick()}
                  className={css(CommonStyles.menuItemContainer)}>
                  <Icon className={css(Styles.icons)} color='secondary'>settings</Icon>
                  <p className={css(Styles.iconsText)}>SETTINGS</p>
                </MenuItem>
              }
              <MenuItem
                onClick={this.props.onLogoutClick}
                className={css(CommonStyles.menuItemContainer)}>
                <Icon className={css(Styles.icons)} color='secondary'>power_settings_new</Icon>
                <p className={css(Styles.iconsText)}>LOGOUT</p>
              </MenuItem>
               <MenuItem
                onClick={() => this.showRecentSessionsViewer()}
                className={css(CommonStyles.menuItemContainer)}>
                <Icon className={css(Styles.icons)} color='secondary'></Icon>
                <p className={css(Styles.iconsText)}>{'Recent Sessions'.toUpperCase()}</p>
              </MenuItem>
              <p className={css(Styles.versionNumber)}>Version {AppConfig.Version}</p>
            </div>
          </Popover>
        </div>
      )
    }
  }

  render() {
    this.fullScreenMode = Controller.get().userMgr().getFullScreenMode()
    if (this.isFullScreenMode()) {
      return <div></div>
    }

    return (
      <div>
        <AppBar className={css(Styles.appbar)} color='primary' position='fixed' elevation={0}>
          <Toolbar className={css(Styles.toolbar)}>
            {this.renderLogo()}

            {/*<Breadcrumbs items={BreadcrumbsHelper.getRoutes(this.props.path)}/>*/}

            {this.renderTabs()}

            {this.renderMenuPopover()}

            {this.renderIdleTimer()}
          </Toolbar>
        </AppBar>

        {this.renderDialog()}
        {this.state.orgSwitcher &&
          this.renderOrgSwitcher()
        }
        {
          this.renderRecentSessions()
        }
      </div>
    );
  }

}

const Styles = StyleSheet.create({
  appbar: {
    backgroundColor: '#202633',
    height: 65,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:"fixed !important",
    zIndex: '10'
  },
  toolbar: {
    width: '90%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  logoImageContainer: {
    width: 'auto',//300,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      height: 25,
    }
  },
  headerContentContainer: {
    display: 'flex',
    padding: '0px 20px',
    textAlign: 'center',
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'center'
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  logoContainer: {
    display: 'flex',
    width:"20%"
  },
  icons: {
    color: '#757575',
    marginRight: 5,
    fontSize: 17
  },
  iconsText: {
    margin: 0,
    fontSize: 12,
    opacity: .7
  },
  dialog: {
    backgroundColor: AppTheme.primaryColor,
    minWidth: 500,
    '@media(max-width: 768px)': {
      minWidth: '-webkit-fill-available',
      margin: 5
    }
  },
  scrollPaper: {
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      overflow: 'scroll',
      justifyContent: 'flex-start'
    }
  },
  dialogTitle: {
    padding: 20,
    display: 'flex',
    backgroundColor: AppTheme.white,
    '@media(max-width: 767px)': {
      padding: '20px 10px',
    }
  },
  dialogTitleContent: {
    fontWeight: 600,
    fontSize: AppTheme.fontSizeTitle,
    margin: 0,
    cursor: 'default'
  },
  closeIconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogContent: {
    padding: 20,
    paddingBottom: 0,
    backgroundColor: AppTheme.primaryBackground,
    '@media(max-width: 767px)': {
      padding: '20px 10px 0px 10px',
    }
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppTheme.primaryBackground,
    paddingBottom: 25,
    borderRadius: 5,
  },
  fieldsContainerHeader: {
    margin: 0,
    //marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 15,
    color: AppTheme.white,
    cursor: 'default',
    opacity: .7
  },
  switchControlLabelChecked: {
    color: ColourPalette.color3,
  },
  switchControlLabeltrack: {
    backgroundColor: ColourPalette.color3
  },
  formControlLabel: {
    fontWeight: 'bolder',
    fontSize: 17,
    color: AppTheme.white
  },
  inputCss: {
    flex: 1,
    color: AppTheme.white
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0px 20px 20px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: AppTheme.primaryBackground,
    '@media(max-width: 767px)': {
      padding: '0px 10px 20px 10px',
    }
  },
  actionButton: {
    backgroundColor: ColourPalette.color3,
    color: AppTheme.white,
    fontWeight: 'bolder',
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    textTransform: 'unset',
    padding: '0px 16px'
  },
  select: {
    color: AppTheme.white,
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10
  },
  inputLabelRoot: {
    zIndex: 1,
    marginLeft: 8,
    opacity: .5,
    color: AppTheme.white,
    pointerEvents: 'none'
  },
  inputLabelFocused: {
    color: AppTheme.white
  },
  selectMenu: {
    backgroundColor: AppTheme.secondaryColor,
    color: AppTheme.white,
    paddingLeft: 8
  },
  selectIcon: {
    color: AppTheme.white
  },
  check: {
    color: AppTheme.white
  },
  versionNumber: {
    margin: 0,
    marginBottom: 5,
    fontSize: 10,
    textAlign: 'center',
    outline: 'none',
    cursor: 'default',
    color: AppTheme.white
  },
  formErrorMsg: {
    margin: 0,
    marginTop: 10,
    color: 'white',
  },
  loadingView: {
    maxHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  personIcon: {
    paddingRight: 8,
    cursor: 'pointer',
    marginLeft:"1rem"
  },
  menuItemPopoverContainer: {
    width: 'unset',
    marginTop: 10,
    minWidth: 250
  },
  linkContentContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  linkContainer: {
    color: '#FFFFFF',
    minWidth: 'fit-content',
    opacity: 0.7,
    fontSize: AppTheme.fontSizeTitle,
    padding: 8,
    paddingBottom: 5,
    borderBottom: '2px solid #202633',
    borderRadius: 1,
    '@media(max-width: 767px)': {
      fontSize: 11,
    }
  },
  linkContainerTab: {
    color: '#FFFFFF',
    minWidth: 'fit-content',
    //opacity: 0.7,
    fontSize: AppTheme.fontSizeTitle,
    padding: 8,
    paddingBottom: 5,
    borderBottom: '2px solid #6B5FFD',
    borderRadius: 1,
    '@media(max-width: 767px)': {
      fontSize: 11,
    }
  },
  menuHeaderUserInfo: {
    display: 'flex',
    backgroundColor: AppTheme.white,
  },
  menuHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppTheme.white,
    padding: 15,
  },
  menuHeaderEmail: {
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 16,
    flex: 1,
    textOverflow: 'ellipsis'
  },
  menuHeaderOrgTitle: {
    margin: 0,
    marginTop: 5,
    color: AppTheme.primaryBackground,
    fontSize: 16,
    flex: 1,
    textOverflow: 'ellipsis'
  },
  menuHeaderRoleConatiner: {
    backgroundColor: ColourPalette.color1,
    marginLeft: 5,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center'
  },
  menuHeaderRole: {
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 10,
    fontWeight: 900
  },
  menuContainer: {
    padding: 5
  },
  searchContainer:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    border: '1px solid #fff',
    padding:'0.3rem'
  },
  incidentErrMessageContainer:{
    padding: '0px !important',
    margin: '0px !important',
    fontSize: '0.8em'
  },
  incidentSearch:{
    borderRadius: '4px !important',
    backgroundColor: 'rgb(22, 28, 38) !important'
  }
})