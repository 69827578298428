import { AppConfigCustom } from './AppConfigCustom';
/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const AppConfig = {
  "Contact": {
    email: 'dashboard@uncannyvision.com'
  },
  "Name": "Eagle Eye Networks",
  "CompanyName": "Eagle Eye Networks",
  "Version": "5.5.1",
  "TERMS": "https://TODO/terms",
  "SUPPORT_EMAIL": "dashboard@uncannyvision.com",
  ...AppConfigCustom,
}