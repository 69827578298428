/* eslint-disable */
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import async from 'async';
import Icon from '@material-ui/core/Icon';
import { Button, Input, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckboxComponent } from '../../components/CheckboxComponent';
import { SearchHelper } from '../../helpers/SearchHelper';
import { AppHelper } from '../../helpers/AppHelper.js'
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { adminData } from '../../data/AdminData.js';
import { appData } from '../../data/AppData.js';
import { AppConfig } from '../../AppConfig';
import { CommonStyles } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { ColourPalette } from '../../styles/AppColourPalette';
import { Event } from '../ClientPlace/Event';
import { ChartComponent } from '../ClientPlace/ChartComponent';
import '../../index.css'
import { SelectComponentV2 } from '../../components/SelectComponentV2';
import { Controller } from '../../common/Controller';


let violationReasonList = {
    "Cellphone": {
        "Okay": {
            label: 'Reason',
            "options": [
                {
                    name: 'No Mobile Detected, Agent hand came towards chin',
                    key: 'No Mobile Detected, Agent hand came towards chin',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards Nose',
                    key: 'No Mobile Detected, Agent hand came towards Nose',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards ear',
                    key: 'No Mobile Detected, Agent hand came towards ear',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards Mouth',
                    key: 'No Mobile Detected, Agent hand came towards Mouth',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards cheek',
                    key: 'No Mobile Detected, Agent hand came towards cheek',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards ear and he is wearing proper headset',
                    key: 'No Mobile Detected, Agent hand came towards ear and he is wearing proper headset',
                },
                {
                    name: 'No Mobile Detected, Agent Background and Cable',
                    key: 'No Mobile Detected, Agent Background and Cable',
                },
                {
                    name: 'No Mobile Detected, Agent hand came towards head',
                    key: 'No Mobile Detected, Agent hand came towards head',
                },
                {
                    name: 'Testing, Mobile Detected',
                    key: 'Testing, Mobile Detected',
                },
                {
                    name: 'No Mobile Detected',
                    key: 'No Mobile Detected',
                }

            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Mobile Detected, Agent identified with Mobile',
                    key: 'Mobile Detected, Agent identified with Mobile',
                },
                {
                    name: 'No Mobile Detected but he was speaking through ear phone',
                    key: 'No Mobile Detected but he was speaking through ear phone',
                },
                {
                    name: 'Testing',
                    key: 'Testing',
                }
            ]
        },
    },
    "NoPerson": {
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Dark Image Captured',
                    key: 'Dark Image Captured',
                },
                {
                    name: 'Face is Visible',
                    key: 'Face is Visible',
                },
                {
                    name: 'Flip Image',
                    key: 'Flip Image',
                },
                {
                    name: 'Face Visible but Blur image',
                    key: 'Face Visible but Blur image',
                },
                {
                    name: 'Dark Image Captured, Light was not switched On',
                    key: 'Dark Image Captured, Light was not switched On',
                },
                {
                    name: 'Testing,Not at Desk',
                    key: 'Testing,Not at Desk',
                }

            ]
        }, "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Agent not at desk, moved out without locking the system',
                    key: 'Agent not at desk, moved out without locking the system',
                },
                {
                    name: 'Face not Visible',
                    key: 'Face not Visible',
                },
                {
                    name: 'Partial Face Visible',
                    key: 'Partial Face Visible',
                }
            ]
        }
    },
    "MultiPerson": {
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'No Multiple Person Identified, Agent facing towards Keyboard',
                    key: 'No Multiple Person Identified, Agent facing towards Keyboard',
                },
                {
                    name: 'No Multiple Person Identified, Agent shadow reflecting on the wall',
                    key: 'No Multiple Person Identified, Agent shadow reflecting on the wall',
                },
                {
                    name: 'No Multiple Person Identified, It is Clubbed image of the agent',
                    key: 'No Multiple Person Identified, It is Clubbed image of the agent',
                },
                {
                    name: 'No Multiple Person Identified, Agent hand came towards Mouth',
                    key: 'No Multiple Person Identified, Agent hand came towards Mouth',
                },
                {
                    name: 'No Multiple Person Identified, Agent hand came towards ear',
                    key: 'No Multiple Person Identified, Agent hand came towards ear',
                },
                {
                    name: 'No Multiple Person Identified, Window Screen',
                    key: 'No Multiple Person Identified, Window Screen',
                },
                {
                    name: 'No Multiple Person Identified, Agent hand was raised',
                    key: 'No Multiple Person Identified, Agent hand was raised',
                },
                {
                    name: 'No Multiple Person Identified, Agent was yawning',
                    key: 'No Multiple Person Identified, Agent was yawning',
                },
                {
                    name: 'No Multiple Person Identified, Reflection on agent spectacles',
                    key: 'No Multiple Person Identified, Reflection on agent spectacles',
                },
                {
                    name: 'No Multiple Person Identified, It is Agent chair and background screens',
                    key: 'No Multiple Person Identified, It is Agent chair and background screens',
                },
                {
                    name: 'No Multiple Person Identified, It is Agent background Poster',
                    key: 'No Multiple Person Identified, It is Agent background Poster',
                },
                // {
                //     name: 'No Mobile Detected, Agent hand came towards Forehead',
                //     key: 'No Mobile Detected, Agent hand came towards Forehead',
                // },
                {
                    name: 'Multiple Person Identified, but away from the agent and has no view of system',
                    key: 'Multiple Person Identified, but away from the agent and has no view of system',
                },
                {
                    name: 'Testing,Multiple Person',
                    key: 'Testing,Multiple Person',
                },

            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Multiple Person Identified, Family member came towards agent system holding Mobile',
                    key: 'Multiple Person Identified, Family member came towards agent system holding Mobile',
                },
                {
                    name: 'Multiple Person Identified, Agent Kid came closer to the system',
                    key: 'Multiple Person Identified, Agent Kid came closer to the system',
                },
                {
                    name: 'Multiple Person Identified, Family member standing behind agent system',
                    key: 'Multiple Person Identified, Family member standing behind agent system',
                },
                {
                    name: "Multiple Person Identified, Family member Sitting next to agent system",
                    key: "Multiple Person Identified, Family member Sitting next to agent system"
                }

            ]
        }
    },
    "BadQuality":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Not a Bad Quality',
                    key: 'Not a Bad Quality',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Bad Quality',
                    key: 'Bad Quality',
                }
            ]
        }
    },
    "Book":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Not a book',
                    key: 'Not a book',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Book',
                    key: 'Book',
                }
            ]
        }
    },
    "UnauthorizedFace":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Not an unauthorized face',
                    key: 'Not an unauthorized face',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Unauthorized face',
                    key: 'Unauthorized face',
                }
            ]
        }
    },
    "Confused":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'No confusion',
                    key: 'No confusion',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Confusion',
                    key: 'Confusion',
                }
            ]
        }
    },
    "FaceTrainingRequired":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Face training not required',
                    key: 'Face training not required',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Face training required',
                    key: 'Face training required',
                }
            ]
        }
    },
    "FaceTraining":{
        "Okay": {
            label: 'Reason',

            "options": [
                {
                    name: 'Face training not required',
                    key: 'Face training not required',
                },
            ]
        },
        "Escalate": {
            label: 'Reason',

            "options": [
                {
                    name: 'Face training required',
                    key: 'Face training required',
                }
            ]
        }
    },

}

export class Violations extends Component {
    constructor(props) {
        super(props);
        this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
        this.eventsByGroupId = {}
        this.grpDisplayMode = true
        this.dateMode = 'recent'
        this.responseByKey = {
            selectedPlaceIds: [],
            selectedCamIds: [],
            toDateTs: null,
            fromDateTs: null,
            eventFields: [],
            eventImages: [],
            priorityFilters: [],
            tagFilters: [],
        }
        this.tagValue = {}
        this.pdfAttrColWidth = {
            camId: 80,
            // eslint-disable-next-line camelcase
            event_time: 120,
            name: 90,
            type: 90,
            tags: 120,
            priority: 5,
            // priorityReported: 5,
        }
        this.eventsByType = {}
        this.state = {
            fetchState: ApiHelper.State.LOADING,
            eventList: [],
            eventCompleteList: [],
            eventListLoading: false,
            pageNumber: 0,
            downloadDialog: false,
            showEvent: false,
            notToPrint: false,
            showChart: false,
            totalCountOfEvents: 0,
            isHovered: false,
            imageId: -1,
            violationConclusionList: {},
            reasonList: {},
            completedRows:[],
            purgedRows:[],
            loadConfirmState:'',
            enableAssginment:false,
            eventCountLoading:false
        }

        this.placePicker = {
            key: 'selectedPlaceIds',
            type: 'checkbox',
            options: [],
        }
        this.chartData = [
        ],
            this.eventListByDate = [],
            this.typeOfFile = ''
    }

    handleMouseEnter = (index) => {
        this.setState({
            isHovered: !this.state.isHovered,
            imageId: index
        })
    };

    handleMouseLeave = () => {
        this.setState({
            isHovered: false,
            imageId: -1
        })
    };


    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchItems()
        this.updateEventsCount()
        window.addEventListener('click', this.handleClick);

    }

    componentWillUnmount() {
        // Remove the click event listener when the component unmounts to prevent memory leaks
        window.removeEventListener('click', this.handleClick);
      }
    

    handleClick = (event) => {
        if(event.target.localName == "img")return
        // Handle the click event here
        console.log('Mouse clicked at:', event.clientX, event.clientY);
        this.handleMouseLeave()
      }

    fetchItems() {
        this.setState({
            fetchState: ApiHelper.State.LOADING
        })

        ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], { orgId: this.orgId }) },
            function (err, results) {
                if (err) {
                    this.setState({
                        fetchState: ApiHelper.State.ERROR,
                        errMsg: Helper.getErrorMsg(err)
                    })
                    return
                }

                this.responseByKey.selectedCamIds.push("selectAll");
                this.responseByKey.selectedPlaceIds.push("selectAll")
                this.setState({
                    org: results,
                    place: results.length > 0 ? results[0] : null,
                }, () => this.updateEvents(undefined, undefined, true))
            }.bind(this))
    }

    setFields = (results) => {
        appData.reportFields.selectCam.options = []
        const selectAllField = AppHelper.getSelectAllOption()
        if (!results) {
            for (const o of this.state.org) {
                if (this.responseByKey.selectedPlaceIds.indexOf(o._id) >= 0) {
                    if (o.cams && o.cams.length > 0) {
                        if (appData.reportFields.selectCam.options.indexOf(selectAllField) === -1) {
                            appData.reportFields.selectCam.options.push(selectAllField)
                        } //NOTE: Adding select all to cam fields
                        for (const cam of o.cams) {
                            appData.reportFields.selectCam.options.push({
                                key: cam._id,
                                name: cam.name
                            })
                        }
                    }
                }
            }
            const selectedCamIds = []
            for (const placeId of this.responseByKey.selectedPlaceIds) {
                for (const o of this.state.org) {
                    if (o._id === placeId) {
                        for (const cam of o.cams) {
                            if (this.responseByKey.selectedCamIds.indexOf(cam._id) >= 0) {
                                selectedCamIds.push(cam._id)
                            }
                        }
                    }
                }
            }
            this.responseByKey.selectedCamIds = selectedCamIds
            return
        }

        appData.reportFields.eventFields.options = adminData.eventFields
        appData.reportFields.eventImages.options = adminData.eventImages

        if (this.props.location.state && this.props.location.state.selectedPlaceIds) {
            this.responseByKey.selectedPlaceIds = this.props.location.state.selectedPlaceIds
        }
        if (this.props.location.state && this.props.location.state.selectedCamIds) {
            this.responseByKey.selectedCamIds = this.props.location.state.selectedCamIds
        }
        if (this.props.location.state && this.props.location.state.toDateTs) {
            this.responseByKey.toDateTs = this.props.location.state.toDateTs
        }
        if (this.props.location.state && this.props.location.state.fromDateTs) {
            this.responseByKey.fromDateTs = this.props.location.state.fromDateTs
        }
        if (this.props.location.state && this.props.location.state.tagValue) {
            this.tagValue = this.props.location.state.tagValue
        }
        if (this.props.location.state && this.props.location.state.tagFilters) {
            this.responseByKey.tagFilters = this.props.location.state.tagFilters
        }
        if (this.props.location.state && this.props.location.state.priorityFilters) {
            this.responseByKey.priorityFilters = this.props.location.state.priorityFilters
        }

        if (results && results.length > 0) {
            if (this.responseByKey.selectedPlaceIds && this.responseByKey.selectedPlaceIds.length === 0) {
                this.responseByKey.selectedPlaceIds = [results[0]._id]
                if (results[0] && results[0].cams.length > 0) {
                    this.responseByKey.selectedCamIds = [results[0].cams[0]._id]
                } else {
                    this.responseByKey.selectedCamIds = []
                }
            } else if (this.responseByKey.selectedCamIds && this.responseByKey.selectedCamIds.length === 0) {
                this.responseByKey.selectedCamIds = []
                for (const placeId of this.responseByKey.selectedPlaceIds) {
                    for (const r of results) {
                        if (r._id === placeId && r.cams && r.cams.length !== 0) {
                            this.responseByKey.selectedCamIds.push(r.cams[0]._id)
                        }
                    }
                }
            }
        }

        this.placePicker.options = []
        if (this.placePicker.options.indexOf(selectAllField) === -1 && results && results[0]) {
            this.placePicker.options.push(selectAllField)
        }
        if (appData.reportFields.selectCam.options.indexOf(selectAllField) === -1 &&
            results && results[0] &&
            results[0].cams &&
            results[0].cams.length > 0) {
            appData.reportFields.selectCam.options.push(selectAllField)
        }

        for (const placeId of this.responseByKey.selectedPlaceIds) {
            for (const r of results) {
                this.placePicker.options.push({
                    key: r._id,
                    name: r.name
                })
                if (r._id === placeId) {
                    for (const cam of r.cams) {
                        appData.reportFields.selectCam.options.push({
                            key: cam._id,
                            name: cam.name
                        })
                    }
                }
            }
        }
    }

    updateEventsCount = () => {
        this.setState({
            eventCountLoading:true,
        })
        
        const params = {
            category:'violation',
        }
        params.camId =  "selectAll"
        params.placeId = "selectAll" 
        params.orgId = this.orgId
        params.fetchCount = true

        const options = {
            method: 'GET',
            endPoint: ApiHelper.makeUrlPath(['camevents'], params)
        }

        const thisObj = this
        const fncs = []
        fncs.push(function (callback) {
            ApiHelper.call(options, callback)
        })

        async.parallel(
            fncs,
            function (err, result) {
                if (err) {
                    thisObj.setState({
                        fetchState: ApiHelper.State.ERROR,
                        errMsg: Helper.getErrorMsg(err)
                    })
                    return
                }
                thisObj.setState({
                  totalCountOfEvents : result[0].totalCount,
                  eventCountLoading:false
                })
            }.bind(this))
    }

    updateEvents = (action, call, assigned, assignToUser) => {
        this.setState({
            eventListLoading:true,
            eventList:[],
            eventCompleteList:[],
            loadConfirmState:'',
            violationConclusionList: {},
            reasonList: {},
            completedRows:[],
            purgedRows:[],
        })
        let user = Controller.get().userMgr().getUser();
        
        const params = {
            category:'violation',
            reviewed:true,
            limit:20
        }
        if(assigned){
            params["userId"] = user._id
        }
        if(assignToUser){
            params["assigned"] = true
        }
        params.camId = this.responseByKey.selectedCamIds
        params.placeId = this.responseByKey.selectedPlaceIds
        params.orgId = this.orgId
        
        const options = {
            method: 'GET',
            endPoint: ApiHelper.makeUrlPath(['camevents'], params)
        }

        const thisObj = this
        const fncs = []
        fncs.push(function (callback) {
            ApiHelper.call(options, callback)
        })

        async.parallel(
            fncs,
            function (err, results) {

                if (err) {
                    thisObj.setState({
                        fetchState: ApiHelper.State.ERROR,
                        errMsg: Helper.getErrorMsg(err)
                    })
                    return
                }
                let modifiedList = this.grpDisplayMode ? this.processEvents(results[0].items) : this.filterWithParams(results[0].items)
                thisObj.setState({
                    eventCompleteList: results[0].items,
                    eventList: modifiedList,
                    eventListLoading: false,
                    // totalCountOfEvents: results[0].totalCount && results[0].totalCount > 0 ? results[0].totalCount : 0,
                    enableAssginment:assignToUser || (results[0].items && results[0].items.length>0 && results[0].items[0] &&results[0].items[0].violation)? true :false
                }, () => {
                    if (results.length > 1) {
                      
                    } else {
                        thisObj.setState({
                            showChart: false,
                            fetchState: ApiHelper.State.READY
                        })
                    }
                    if (call) {
                        call()
                    }
                })
            }.bind(this))
    }

    processEvents = (items) => {
        items = this.filterWithParams(items)
        this.eventsByGroupId = {}
        const modifiedEventList = []
        for (const item of items) {
            if (!item.groupId) {
                modifiedEventList.push(item)
                continue
            }

            if (this.eventsByGroupId[item.groupId] === undefined) {
                modifiedEventList.push(item)
                this.eventsByGroupId[item.groupId] = []
            } else {
                this.eventsByGroupId[item.groupId].push(item)
            }
        }

        this.setEventsByType(modifiedEventList)
        return modifiedEventList
    }

    setEventsByType = (events) => {
        this.eventsByType = {}
        for (const event of events) {
            if (event.type === 'null' || event.type === 'undefined') {
                continue
            }
            if (!event.type) {
                continue
            }
            if (this.eventsByType[event.type]) {
                this.eventsByType[event.type] = this.eventsByType[event.type] + 1
            } else {
                this.eventsByType[event.type] = 1
            }
        }
    }

    setValue = (value, key) => {
        this.responseByKey[key] = value
        this.setState({})
    }

    filterWithParams = (items) => {
        let eventListAfterFilter = []
        if (this.searchText) {
            eventListAfterFilter = SearchHelper.filterItemsBySearchText(this.searchText, items)
        } else {
            eventListAfterFilter = items
        }

        if (this.responseByKey.tagFilters && this.responseByKey.tagFilters.length > 0) {
            eventListAfterFilter = SearchHelper.filterItemsByTag(this.responseByKey.tagFilters, this.tagValue, eventListAfterFilter)
        }

        if (this.responseByKey.priorityFilters && this.responseByKey.priorityFilters.length > 0) {
            eventListAfterFilter = SearchHelper.filterItemsByPriority(this.responseByKey.priorityFilters, eventListAfterFilter)
        }

        this.setEventsByType(eventListAfterFilter)

        return eventListAfterFilter
    }

    refreshViolations = (assign) =>{
        console.log("refreshing violations")
        this.updateEvents(undefined, ()=>{
            this.updateEventsCount()
        }, assign, true)
    }

    renderFilterResult = () => {
        return (
            <div className={css(Styles.eventContainer)}>
                <div className={css(Styles.eventContainerHeader)}>

                    <div className={css(Styles.statsContainer)}>
                        <p className={css(Styles.headerContainer)}> {"Violations to be reviewed  |  "} {this.state.eventCountLoading ? <CircularProgress color='#fff' size={20} /> : this.state.totalCountOfEvents} </p>


                        <div className={css(Styles.eventEditContainer, Styles.eventEditContainerStyle)}>
                            {Object.keys(this.eventsByType).map((key, index) => {
                                return (
                                    <div key={index} className={css(Styles.tagContainer)}>
                                        <p className={css(Styles.tagKey)}>{key}</p>
                                        <p className={css(Styles.tagValueVariant)}>{this.eventsByType[key]}</p>
                                    </div>
                                )
                            })}

                            {AppConfig.ENABLE_REFRESH_VIOLATION &&
                                <Button
                                    variant='contained'
                                    onClick={() => this.refreshViolations(true)}
                                    className={css(Styles.dialogActionButton)}
                                > Assign
                                </Button>
                            }
                        </div>

                    </div>
                </div>

                {this.eventTable()}

            </div>
        )
    }

    renderFilters = () => {
        return (
            <div className={css(Styles.formOuterContainer)}>
                <div className={css(Styles.formInnerContainer)}>
                    <CheckboxComponent
                        orgType={this.state.org[0].type}
                        inputCss={{ flex: 1 }}
                        formField={appData.reportFields.eventFields}
                        value={this.responseByKey[appData.reportFields.eventFields.key] ? this.responseByKey[appData.reportFields.eventFields.key] : []}
                        setValue={this.setValue.bind(this)}
                        disabled={false} />
                    <CheckboxComponent
                        inputCss={{ flex: 1 }}
                        formField={appData.reportFields.eventImages}
                        value={this.responseByKey[appData.reportFields.eventImages.key] ? this.responseByKey[appData.reportFields.eventImages.key] : []}
                        setValue={this.setValue.bind(this)}
                        disabled={false} />
                </div>
            </div>
        )
    }

    getPlaceId = (item) => {
        for (const o of this.state.org) {
            for (const c of o.cams) {
                if (item.camId === c._id) {
                    return o._id
                }
            }
        }
    }

    onEventClick = (item) => {
        this.placeId = this.getPlaceId(item)
        this.setState({
            selectedEventId: item._id,
            showEvent: true
        })
    }

    setViolationConclusion = (id, event, value) => {
        let violationConclusion = this.state.violationConclusionList
            violationConclusion[id] = value
            this.setState({
                violationConclusionList:violationConclusion,
            })
    }

    saveViolation = (item) =>{
        this.setState({
            loadConfirmState : item._id
        })
        let id = item._id
        let violationConclusion = this.state.violationConclusionList[id]
        let violationReason= this.state.reasonList[id] ?this.state.reasonList[id] : 'Reason not available'
        let org = Controller.get().userMgr().getCurrentOrg();
        let user = Controller.get().userMgr().getUser();

        let tags = {};
        if (item.tags) {
          tags = item.tags;
        }
        
    
        if (org.managerType == "PROJECT_MANAGER") {
          tags["project_manager_review"] = violationConclusion == "Escalate" ? "Escalated" : violationConclusion == "Okay" ? "Okayed" : '';
        } else {
          tags["org_manager_review"] = violationConclusion == "Escalate" ? "Escalated" : violationConclusion == "Okay" ? "Okayed" : '';
        }

        let data = {
            comment:violationReason,
            state:violationConclusion == "Escalate" ? "Escalated" : violationConclusion == "Okay" ? "Okayed" : '',
            managerType: org.managerType ? org.managerType:"PROJECT_MANAGER",
            userId: user._id,
            timestamp: new Date().toString(),
            name: user.name? user.name:"ADMIN",
            email: user.email,
            roles: org.roles,
            tags: tags,
            assigned:true
        }

        this.editEvent(id, "saveViolation", data, (err)=>{
            if(!err){
            let rows = this.state.completedRows
            rows.push(item._id)
            this.setState({
                completedRows : rows,
                loadConfirmState:''
            })
        //   this.updateEvents()
            
        }
        })
        
    }

    purgeViolation = (id) => {
        this.editEvent(id, "purge", undefined, (err) => {
            if(!err){
            let rows = this.state.purgedRows
            rows.push(id)
            this.setState({
                purgedRows: rows
            })}
        })
    }

    setReasonForViolation = (value, index, id) =>{
        let reasonList = this.state.reasonList
        reasonList[id] = value
        this.setState({
            reasonList:reasonList
        })
    }

    editEvent = (id, mode, data, callback) => {
        let options = {}
        let user = Controller.get().userMgr().getUser();

        if (mode === 'purge') {
          options = {
            method: 'PUT',
            endPoint: ApiHelper.makeUrlPath(['camevents', id]),
            jsonBody: {
                purge: true,
                userId:user._id
            }
          }
        }  else if (mode === 'saveViolation') {
    
            options = {
            method: 'PUT',
            endPoint: ApiHelper.makeUrlPath(['camevents', id]),
            jsonBody: {
                latestComment: data
            }
          }
        }
        else {
          return
        }
        ApiHelper.call(options, function (err, data) {
          if (err) {
            const msg = err.message ? err.message : Helper.getString('unassignedViolation')
            this.setState({
              errMsg: msg,
              errCode: err.respCode,
              eventList:[],
              
            }, ()=>{
                callback("err")
            })
            return
          }
          this.setState({
            eventEditor: false,
            errMsg: '',
            errCode:'',
          }, ()=>{
              callback()
          })
    
        }.bind(this))
      };

    eventTable = () => {
        if (this.state.eventListLoading) {
            return (
                <div className={css(Styles.progressContainer)}>
                    <CircularProgress size={30} className={css(Styles.progress)} />
                </div>
            )
        }
        if(this.state.eventList.length == 0){
            return <h1   className={css(Styles.noViolationFound)} >{this.state.errMsg ?this.state.errMsg :  "No violations found"}</h1>
        }

        return (
            <Table className={css(Styles.tableOuterContainer)}>
                <TableHead className={css(Styles.tableHead)}>
                    <TableRow className={css(Styles.tableRowHeader)}>
                        <TableCell
                            align='center'
                            className={css(Styles.tableHeader)}>
                            <p>SL.No</p>
                        </TableCell>

                        {(!this.responseByKey.eventFields || this.responseByKey.eventFields.length === 0) ?
                            adminData.violationFieldsDefault.map((events, i) => {
                                return (
                                    <TableCell key={i}
                                        align='center'
                                        className={css(Styles.tableHeader)}>
                                        {this.state.org && this.state.org[0].type == "wfhTeams" && events.name_wfh ? events.name_wfh : events.name}
                                    </TableCell>
                                )
                            })
                            :
                            adminData.eventFields.map(field => {
                                return (
                                    this.responseByKey.eventFields.map(key => {
                                        return (
                                            key === field.key &&
                                            <TableCell
                                                align='center'
                                                className={css(Styles.tableHeader)}>
                                                {this.state.org && this.state.org[0].type == "wfhTeams" && field.name_wfh ? field.name_wfh : field.name}
                                            </TableCell>
                                        )
                                    }))
                            })
                        }

                    </TableRow>
                </TableHead>

                <TableBody className={css( this.state.enableAssginment ? "" : Styles.disabledRow)}>
                    {this.responseByKey.eventFields.length === 0 ?
                        this.state.eventList.map((item, index) => {
                            return (
                                <TableRow key={index} className={css(Styles.tableRow, this.state.completedRows.includes(item._id) ? Styles.disabledRow : "")}>
                                    <TableCell align='center' className={css(Styles.tableItem)}>
                                        <p>{index + 1 + (this.state.pageNumber * AppConfig.eventQueryLimit)}</p>
                                    </TableCell>
                                    <TableCell align='center' className={css(Styles.tableItem)}>{this.renderPlaceName(item)}</TableCell>
                                    <TableCell align='center' className={css(Styles.tableItem)}>{this.renderCamName(item)}</TableCell>
                                    <TableCell align='center' className={css(Styles.tableItem)}>{item.type}</TableCell>
                                    <TableCell align='center' className={css(Styles.tableItem)}> {moment(item.event_time).format('MMM DD, YYYY, h:mm:ss A')}</TableCell>
                                    <TableCell align='center' style={{
                                        overflow: 'hidden',
                                        transition: 'transform 0.3s',
                                        transform: this.state.isHovered && this.state.imageId == index && item.image ? 'scale(6)' : 'scale(1)',
                                        position: "relative",
                                        zIndex: this.state.isHovered && this.state.imageId == index ? 10000 : 'unset'

                                    }}
                                    >
                                        {this.responseByKey.eventImages.length === 0 ?
                                            <div className={css(Styles.eventListImageContainer, Styles.purge)} onClick={() => this.handleMouseEnter(index)} >
                                                <img
                                                    key={item.image}
                                                    src={item.image && !this.state.purgedRows.includes(item._id) ? item.image : '../../assets/images/error2.svg'}
                                                    // alt={""}
                                                    // style={{position: this.state.isHovered && this.state.imageId == index && item.image ? "absolute":"relative"}}
                                                    className={css(Styles.eventImg)}
                                                    onError={(e) => {
                                                        e.target.src = require('../../assets/images/error2.svg')
                                                    }}
                                                ></img>
                                                {item.image && !this.state.purgedRows.includes(item._id) ? "" : <p style={{ color: "#fff" }}>{"Purged Image"}</p>}
                                            </div>
                                            :
                                            this.responseByKey.eventImages.map(key => {
                                                return (
                                                    this.eventImage(item, key)
                                                )
                                            })
                                        }
                                    </TableCell>

                                    <TableCell  align='center' className={css(Styles.tableItem, Styles.prewrap)}>

                                        {!item["reviews"] || item["reviews"].length == 0 ? <Button
                                            variant='contained'
                                            disabled={this.state.violationConclusionList[item._id] == "Escalate" ? true : false}
                                            onClick={() => this.setViolationConclusion(item._id, item, "Escalate")}
                                            className={css(Styles.dialogActionButton, this.state.violationConclusionList[item._id] == "Escalate" ? Styles.active : "")}
                                        >
                                            Escalate
                                        </Button> : ""}
                                        {!item["reviews"] || item["reviews"].length == 0 ? 
                                        <Button
                                            variant='contained'
                                            disabled={this.state.violationConclusionList[item._id] == "Okay" ? true : false}
                                            onClick={() => this.setViolationConclusion(item._id, item, "Okay")}
                                            className={css(Styles.dialogActionButton, this.state.violationConclusionList[item._id] == "Okay" ? Styles.active : "")}
                                        > Okay
                                        </Button> : <p>{item["reviews"][item["reviews"].length - 1].state}</p>}

                                    </TableCell>

                                    <TableCell align='center' className={css(Styles.tableItem, Styles.reasonSize, !this.state.violationConclusionList[item._id]? Styles.disabledRow : '')}>
                                        {!item["reviews"] || item["reviews"].length == 0 ? <SelectComponentV2
                                            key={"reasonForViolation"}
                                            // disabled={!this.state.violationConclusionList[item._id] }
                                            inputCss={{ flex: 1, width: "100%" , maxWidth:"100%" , whiteSpace:"pre-line"}}
                                            formField={this.state.violationConclusionList[item._id] && violationReasonList[item.type] && violationReasonList[item.type][this.state.violationConclusionList[item._id]] ? violationReasonList[item.type][this.state.violationConclusionList[item._id]] : appData.violationsField.reasonForViolation}
                                            value={this.responseByKey["reasonForViolation"]}
                                            setValue={(value) => this.setReasonForViolation(value, index, item._id)}
                                        /> : <p>{item["reviews"][item["reviews"].length - 1].comment}</p>}
                                    </TableCell>

                                    <TableCell align='center' className={css(Styles.tableItem, Styles.prewrap)}>
                                        {(!item["reviews"] || item["reviews"].length == 0) && (!this.state.completedRows.includes(item._id)) ? <Button
                                            // disabled={!this.state.violationConclusionList[item._id] || !this.state.reasonList[item._id]}
                                            variant='contained'
                                            onClick={() => this.saveViolation(item)}
                                            className={css(Styles.dialogActionButton,  (!this.state.violationConclusionList[item._id] || !this.state.reasonList[item._id] ? Styles.disabledRow : ""))}>
                                            {this.state.loadConfirmState == item._id ? <CircularProgress color='#fff' size={20} /> : ""}
                                            Confirm
                                        </Button> : <Icon className={css(Styles.refreshIcon)} color='primary'>
                                            done
                                        </Icon>}
                                    </TableCell>


                                    {item.image  && !this.state.purgedRows.includes(item._id) ? <TableCell align='center' className={css(Styles.tableItem, Styles.prewrap)}>
                                        <Tooltip title='Purge'>
                                            <IconButton className={css(Styles.refreshIconContainer, Styles.dialogActionButton)} onClick={() => this.purgeViolation(item._id)}>
                                                <Icon className={css(Styles.refreshIcon)} color='primary' >
                                                    delete
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell> : <TableCell></TableCell>}

                                </TableRow>
                            )
                        })
                        :
                        this.state.eventList.map((item, index) => {
                            return (
                                <TableRow
                                 className={css(Styles.tableRow)}>
                                    <TableCell align='center' className={css(Styles.tableItem)}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p>{index + 1 + (this.state.pageNumber * AppConfig.eventQueryLimit)}</p>
                                        </div>
                                    </TableCell>

                                    {adminData.eventFields.map(field => {
                                        return (
                                            this.responseByKey.eventFields.map(key => {
                                                return (
                                                    field.key === key &&
                                                    this.evenSelectedfunction(item, key)
                                                )
                                            })
                                        )
                                    })}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        )
    }

    eventImage = (item, key) => {
        const imgArr = [];
        if (key === 'primaryImage') {
            imgArr.push(
                <div className={css(Styles.eventListImageContainer)}>
                    <img
                        key={item.image}
                        src={item.image}
                        className={css(Styles.eventImg, Styles.eventListImageConatinerSecondary)}></img>
                </div>
            )
        } else if (key === 'secondaryImage' &&
            item.additionalImages &&
            item.additionalImages.length > 0) {
            imgArr.push(
                <div className={css(Styles.eventListImageContainer)}>
                    <img
                        key={item.additionalImages[0]}
                        src={item.additionalImages[0]}
                        className={css(Styles.eventImg)}></img>
                </div>
            )
        }
        return imgArr
    }

    renderTypes = (type) => {
        if (type === 'null' || type === 'undefined') {
            return null
        }
        return type
    }

    renderTags = (item) => {
        if (!item.tags) {
            return ''
        }
        const tagKeys = Object.keys(item.tags)
        return (
            <div className={css(Styles.tagsContainer)}>
                {tagKeys.map((tagK, index) => {
                    return (
                        <p className={css(Styles.tagValue)}>{tagK} : {item.tags[tagK]}</p>
                    )
                })}
            </div>
        )
    }


    evenSelectedfunction = (item, key) => {
        if (key === 'camId') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {item.camId}
                </TableCell>
            )
        }
        else if (key === 'event_time') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {moment(item.event_time).format('MMM DD, YYYY, h:mm:ss A')}
                </TableCell>
            )
        }
        else if (key === 'name') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {item.type}
                </TableCell>
            )
        }
        else if (key === 'priority') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {AppHelper.getPriorityStr(item.priority)}
                </TableCell>
            )
        }
        else if (key === 'priorityReported') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {AppHelper.getPriorityStr(item.priorityReported)}
                </TableCell>
            )
        }
        else if (key === 'type') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.renderTypes(item.type)}
                </TableCell>
            )
        } else if (key === 'tags') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.renderTags(item)}
                </TableCell>
            )
        } else if (key === 'image') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.responseByKey.eventImages.length === 0 ?
                        <div className={css(Styles.eventListImageContainer)}>
                            <img
                                key={item.image}
                                src={item.image}
                                className={css(Styles.eventImg)}></img>
                        </div>
                        :
                        this.responseByKey.eventImages.map(key => {
                            return (
                                this.eventImage(item, key)
                            )
                        })
                    }
                </TableCell>
            )
        } else if (key === 'grouped_events') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.eventsByGroupId[item.groupId] ? (this.eventsByGroupId[item.groupId].length + 1) : 1}
                </TableCell>
            )
        } else if (key === 'placeId') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.renderPlaceid(item)}
                </TableCell>
            )
        } else if (key === 'place_name') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.renderPlaceName(item)}
                </TableCell>
            )
        } else if (key === 'cam_name') {
            return (
                <TableCell align='left' className={css(Styles.tableItem)}>
                    {this.renderCamName(item)}
                </TableCell>
            )
        } else if (key === 'comments') {
            return (
                <TableCell align='left' className={css(Styles.tableItem, Styles.prewrap)}>
                    {this.renderComment(item)}
                </TableCell>
            )
        }
    }

    renderPlaceid = (item) => {
        for (const o of this.state.org) {
            for (const c of o.cams) {
                if (c._id === item.camId) {
                    return o._id
                }
            }
        }
    }

    renderComment = (item) => {
        var text = ""
        for (var i = 0; i < item.reviews.length; i++) {
            let element = item.reviews[i];
            var nameOfmanager = element.name ? element.name : element.email
            var commentByManager = element.comment ? element.comment : "no comment done"

            text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n	
      `
        }
        return text
    }

    renderPlaceName = (item) => {
        for (const o of this.state.org) {
            for (const c of o.cams) {
                if (c._id === item.camId) {
                    return o.name
                }
            }
        }
    }

    renderComment = (item) => {
        var text = ""
        for (var i = 0; i < item.reviews.length; i++) {
            let element = item.reviews[i];
            var nameOfmanager = element.name ? element.name : element.email
            var commentByManager = element.comment ? element.comment : "no comment done"

            text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n
      `
        }
        return text
    }

    renderCamName = (item) => {
        for (const o of this.state.org) {
            for (const c of o.cams) {
                if (c._id === item.camId) {
                    return c.name
                }
            }
        }
    }

    onGroupedDisplayChange = (value) => {
        this.grpDisplayMode = value
        // if (value) {
        //   this.setState({
        //     eventList: this.processEvents(this.state.eventCompleteList)
        //   })
        // } else {
        //   this.eventsByGroupId = {}
        //   this.setState({
        //     eventList: this.filterWithParams(this.state.eventCompleteList)
        //   })
        // }
    }

    handleShowEvent = (value) => {
        this.setState({
            showEvent: value
        })
    }

    renderEvent = () => {
        return (
            <div className={css(Styles.eventContainer)}>
                <div className={css(Styles.eventContainerHeader, Styles.eventContainerHeaderSpec)}>
                    <Event
                        orgLevel={true}
                        handleShowEvent={this.handleShowEvent}
                        eventId={this.state.selectedEventId} />
                </div>

            </div>
        )
    }

    showChartHandler = (value) => {
        this.setState({ showChart: value })
    }

    readyView = () => {
        return (
            <div className={css(CommonStyles.outerMainContainer)}>
                <div className={css(CommonStyles.innerMainContainer)}>
                    <div className={css(Styles.innerContainer)}>
                        {this.state.showChart &&
                            <div>
                                <ChartComponent
                                    data={this.chartData}
                                    showChart={this.state.showChart}
                                    showChartHandler={this.showChartHandler}
                                />
                            </div>
                        }
                        {!this.state.showEvent &&
                            this.renderFilterResult()
                        }
                        {this.state.showEvent &&
                            this.renderEvent()
                        }
                    </div>
                </div>
                {this.state.downloadDialog &&
                    this.downloadDialogFunction()
                }

            </div>
        )
    }

    render() {
        if (this.state.fetchState === ApiHelper.State.LOADING) {
            return UiHelper.componentLoadingView();
        } else if (this.state.fetchState === ApiHelper.State.ERROR) {
            return UiHelper.errorView(this);
        } else {
            return this.readyView();
        }
    }
}


const Styles = StyleSheet.create({
    tableHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        flex: 1,
        minWidth: 'unset',
        color: AppTheme.white,
    },
    tableRowHeader: {
        backgroundColor: '#202633',
        borderRadius: '4px 4px 0 0',
    },
    tableRow: {
        backgroundColor: '#202633',
        borderRadius: '4px 4px 0 0',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: AppTheme.primaryBackground,
        }
    },
    tableItem: {
        color: AppTheme.white,
        fontWeight: 'bolder',
        textTransform: 'capitalize'
    },
    reasonSize:{
        maxWidth:"20rem !important",
        width:"20rem !important"

    },

    tableOuterContainer: {
    },
    tableHead: {
        marginTop: 10,
        backgroundColor: AppTheme.primaryBackground,
    },
    eventListImageContainer: {
        width: 100,
        display: 'flex',
        marginBottom: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    eventListImageConatinerSecondary: {
        marginTop: 5
    },
    eventImg: {
        width: '100%',
        color:"#FFF"
    },
    pageNumber: {
        opacity: 0.7,
        color: AppTheme.white,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            fontSize: 15,
        }
    },
    refreshIconContainer: {
        padding: '0px 12px',
        ':hover': {
            backgroundColor: AppTheme.primaryBackground,
        },
        '@media (max-width: 767px)': {
            padding: '0px 5px',
        }
    },
    refreshIcon: {
        borderRadius: '50%',
        cursor: 'pointer',
        opacity: 0.5,
        color: AppTheme.white,
        ':hover': {
            backgroundColor: '#1D2331',
        }
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    downloadImage: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        '@media (max-width: 767px)': {
            paddingLeft: 5,
            paddingRight: 5,
        }
    },
    formOuterContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: AppTheme.primaryBackground,
        padding: 20,
        paddingTop: 0,
        paddingBottom: 0
    },
    innerContainer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
        marginBottom: 50,
    },
    formInnerContainer: {
        display: 'flex',
        flex: 1,
    },
    eventContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: AppTheme.secondaryColor,
        marginTop: 10,
        overflowX: 'scroll'
    },
    eventContainerHeader: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: AppTheme.primaryBackground,
        padding: 10,
        borderRadius: '4px 4px 0 0',
    },
    eventContainerHeaderSpec: {
        backgroundColor: AppTheme.secondaryColor,
        padding: 0,
    },
    downloadContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    dialogTitle: {
        padding: 20,
        backgroundColor: AppTheme.white
    },
    dialogTitleContainer: {
        display: 'flex',
        justifyContent: "space-between"
    },
    closeIconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer'
    },
    dialogTitleContent: {
        fontWeight: 600,
        fontSize: 16,
        margin: 0,
        color: AppTheme.primaryBackground,
    },
    dialogContent: {
        padding: 0,
        backgroundColor: AppTheme.primaryBackground,
        marginBottom: -1
    },
    dialogContentData: {
        margin: 0,
        padding: '15px 20px 0px 20px',
        fontWeight: 600,
        opacity: 0.6,
        color: AppTheme.white,
    },
    dialogActions: {
        padding: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: AppTheme.primaryBackground,
    },
    dialogActionButton: {
        fontWeight: 'bolder',
        margin: 0,
        marginRight: 10,
        fontSize: 15,
        textTransform: 'unset',
        backgroundColor: ColourPalette.color3,
        color: AppTheme.white,
        padding: '5px'
    },
    headerContainer: {
        fontSize: 18,
        fontWeight: 'bold',
        flex: 1,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        color: AppTheme.white,
        '@media (max-width: 767px)': {
            fontSize: 16,
        }
    },
    downloadIcon: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        color: ColourPalette.color1,
        '@media (max-width: 767px)': {
            paddingLeft: 5,
            paddingRight: 5,
        }
    },
    dialog: {
        minWidth: 500,
    },
    progressContainer: {
        minHeight: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progress: {
        color: ColourPalette.color1
    },
    tagsContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        fontWeight: 'bolder'
    },
    tagValue: {
        margin: 0,
        marginBottom: 5
    },
    statsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    tagContainer: {
        border: '1px solid #566380',
        marginTop: 10,
        marginRight: 15,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tagContainerDetail: {
        border: '1px solid #6c6c6c',
        borderRadius: 5,
    },
    tagKey: {
        backgroundColor: ColourPalette.color1,
        margin: 0,
        color: AppTheme.primaryBackground,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 5,
        borderRadius: '4px 0px 0px 4px',
        cursor: 'default'
    },
    tagValueVariant: {
        margin: 0,
        color: '#C7C9CB',
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 4,
        cursor: 'default'
    },
    noViolationFound: {
        margin: 0,
        color: '#C7C9CB',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 4,
        cursor: 'default'
    },
    eventEditContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    eventEditContainerStyle: {
        marginTop: 10,
        flex: 1,
    },
    menuHeaderRoleConatiner: {
        cursor: 'pointer',
        backgroundColor: ColourPalette.color1,
        marginRight: 5,
        marginLeft: 5,
        borderRadius: 5,
        padding: 4,
        display: 'flex',
        maxWidth: 60,
        justifyContent: 'center',
        alignItems: 'center',
        '@media(max-width: 767px)': {
            marginTop: 5
        }
    },
    menuHeaderRole: {
        margin: 0,
        color: AppTheme.primaryBackground,
        fontSize: 13,
        fontWeight: 'bold'
    },
    inputLabelRoot: {
        color: AppTheme.white
    },
    inputLabelFocused: {
        color: AppTheme.white
    },
    prewrap: {
        whiteSpace: "nowrap"
    },
    active: {
        backgroundColor:"green"
    },
    disabledRow:{
        pointerEvents : "none !important",
        opacity:0.4
    },
    purge:{
        display:"inline-block !important",
        textAlign: 'center'
    }

})