import React, { Component } from 'react';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { AppTheme } from '../styles/AppTheme';
import {
  StyleSheet,
  css
} from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

export class SelectComponentV2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || this.props.formField.defaultValue
        // || this.props.formField.defaultValue
    }
  }
  componentDidMount(){
    if(this.props.value == undefined || this.props.value == null){
      this.setState({ value: this.props.formField.defaultValue});
      this.props.setValue(this.props.formField.defaultValue, this.props.formField.key)
    }else{
      this.setState({ value: this.props.value});
      this.props.setValue(this.props.value, this.props.formField.key)
    }
  }


  onSelect = e => {
    this.setState({ value: e.target.value });
    this.props.setValue(e.target.value, this.props.formField.key)
  };


  render() {
    return (
      <FormControl
        style={this.props.inputCss}
        className={css(Styles.inputCss)}>
        <InputLabel
          className={css(Styles.inputLabel)}
          classes={{
            shrink: css(Styles.inputLabelShrink)
          }}
        >
          {this.props.formField.hint || this.props.formField.label}
        </InputLabel>
        <Select
          className={css(Styles.innerContainer)}
          inputProps={{
            disableUnderline: true,
            name: this.props.formField.key,
          }}
          disabled={this.props.disabled}
          value={this.state.value || this.props.value}
          onChange={this.onSelect}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            classes: {
              paper: css(CommonStyles.menuItemContainerRoot),
              list: css(CommonStyles.menuItemListContainerRoot)
            } }}
          classes={{
            selectMenu: this.props.action === 'viewInfo' ? css(Styles.selectMenu,Styles.textFieldContentInputView) : css(Styles.selectMenu),
            icon: this.props.action === 'viewInfo' ? css(Styles.selectIconView) : css(Styles.selectIcon),
          }}
          input={
            <Input
              disableUnderline={true}
            />
          }
        >
          {this.props.formField.options.map(item => {
            return (
              <MenuItem
                value={item.key}
                className={css(CommonStyles.menuItemContainer)}>
                <p className={css(CommonStyles.margin, Styles.preLine)}>{item.name}</p>
              </MenuItem>
            )
          })}
        </Select>

        <FormHelperText
          className={css(Styles.errorMsgStyle)}>
          {!this.props.enableValidation ? '' : ValidationHelper.validateFields(this.props.formField, this.state.value)}
        </FormHelperText>

      </FormControl>
    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    // flex: 1,
    color: AppTheme.white,
    minHeight: AppTheme.formFieldHeight
  },
  inputLabel: {
    color: AppTheme.white,
    opacity: .6,
    zIndex: 1,
    marginLeft: 8,
    pointerEvents: 'none',
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  inputLabelShrink: {
    right: 'unset'
  },
  innerContainer: {
    color: AppTheme.white
  },
  selectMenu: {
    backgroundColor: AppTheme.secondaryColor,
    color: AppTheme.white,
    paddingLeft: 8,
    borderRadius: 4
  },
  textFieldContentInputView: {
    backgroundColor: AppTheme.primaryBackground,
  },
  selectIcon: {
    color: AppTheme.white,
    opacity: 0.7
  },
  selectIconView: {
    color: AppTheme.primaryBackground
  },
  errorMsgStyle: {
    color: AppTheme.white,
    paddingLeft: 8
  },
  preLine:{
    whiteSpace:"pre-line"
  }
})