import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppTheme } from './styles/AppTheme';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Controller } from './common/Controller';
import { ApiHelper } from './common/helpers/ApiHelper';
import { UiHelper } from './common/helpers/UiHelper';
import { Helper } from './common/helpers/Helper'
import { NoPermission } from './common/components/NoPermission';
import { NotFound } from './screens/NotFound';
import { Terms } from './screens/Terms';
import { Privacy } from './screens/Privacy';
import { Admin } from './screens/Admin';
import { Users } from './screens/Users';
import  OrgReports  from './screens/OrgEvents/OrgReports';
import { Places } from './screens/Places';
import { Place } from './screens/ClientPlace/Place';
import { Cams } from './screens/Cams';
import { Event } from './screens/ClientPlace/Event';
import { CamLive } from './screens/CamLive';
import { GuestUsers } from './screens/GuestUsersDetails/GuestUsers';
import { AppConfig } from './AppConfig';
import { Settings } from './screens/Settings';
import { CamLiveCloud } from './screens/CamLiveCloud';
import { LoginPage } from './screens/LoginPage';
import { Devices } from './screens/Devices';
import { PlaceSettings } from './screens/PlaceSettings';
import { CamSettings } from './screens/CamSettings';
import { CallbackHandler } from './screens/CallbackHandler'
import { Violations } from './screens/OrgEvents/Violations';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.primaryColor
    },
    secondary: {
      main: AppTheme.secondaryColor
    }
  },
  typography: {
    fontFamily: [
      'Quicksand',
      'sans-serif'
    ].join(',')
  }
});

const ProtectedRoute = (props) => {
  const user = Controller.get().userMgr().getloggedInUser();
  if (user) {
    if (props.appUser) {
      return props.checkPermsAndNavigate(props)
    }
    Controller.get().loginMgr().bootstrap((err, data) => {
      if (err) {
        console.error('bootstrap err:', err)
        Controller.get().userMgr().signOut()
        props.setAppUser();
        return
      }
      props.setAppUser();
    })
    return UiHelper.componentLoadingView()
  } else {
    // Controller.get().loginMgr().signOut()
    props.setAppUser(true);
    return <Redirect to='/login' />
  }
}

const PublicRoute = (props) => {
  const link = Controller.get().userMgr().getUserHome()
  if (Controller.get().userMgr().getloggedInUser()) {
    if (props && props.path === '/login') {
      return <Redirect to={link} />
    }
  }
  return <Route {...props} />
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      appUser: null
    };
  }

  componentDidMount() {
    this.setup()
  }

  setup() {
    async.parallel(
      [
        function (callback) {
          ApiHelper.publicApiCall(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'setup']) }, callback)
        }
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        const data = results[0];
        if (data) {
          if (data.allowLocal === true) {
            AppConfig.allowLocal = data.allowLocal
          }
          if (data.autoUpdateEvent !== undefined) {
            AppConfig.autoUpdateEvent = data.autoUpdateEvent
          }
          if (data.NAME !== undefined) {
            AppConfig.NAME = data.NAME
          }
          if (data.placeTypes !== undefined) {
            AppConfig.placeTypes = data.placeTypes
          }
          if (data.camTypes !== undefined) {
            AppConfig.camTypes = data.camTypes
          }
          if (data.orgTypes !== undefined) {
            AppConfig.orgTypes = data.orgTypes
          }
          if (data.DEFAULT_FIELDS !== undefined) {
            AppConfig.DEFAULT_FIELDS = data.DEFAULT_FIELDS
          }
          if (data.SHOW_DEVICES_INFO !== undefined) {
            AppConfig.SHOW_DEVICES_INFO = data.SHOW_DEVICES_INFO
          }
          if (data.DEVICE_HEALTH_CHECK_DURATION_SECONDS !== undefined) {
            AppConfig.DEVICE_HEALTH_CHECK_DURATION_SECONDS = data.DEVICE_HEALTH_CHECK_DURATION_SECONDS
          }
          if (data.SSO !== undefined) {
            AppConfig.SSO = data.SSO
          }
          if (data.ES_EVENT_TYPES !== undefined) {
            AppConfig.ES_EVENT_TYPES = data.ES_EVENT_TYPES
          }
          this.setState({
            fetchState: ApiHelper.State.READY
          });
        }
      }.bind(this))
  }

  checkPermsAndNavigate = (path, props) => {
    if (Controller.get().userMgr().checkUserPermission(path, props.computedMatch)) {
      if (path === '/') {
        return <Redirect to={Controller.get().userMgr().getUserHome()} />
      } else {
        return <Route {...props} />
      }
    } else {
      return UiHelper.getErrorMsg()
    }
  }

  setAppUser = (isLoggedOut) => {
    this.setState({
      appUser: isLoggedOut ? null : Controller.get().userMgr().getUser(),
    })
  }

  getProtectedRoute(path, component) {
    return (
      <ProtectedRoute
        setAppUser={this.setAppUser}
        appUser={this.state.appUser}
        checkPermsAndNavigate={(props) => this.checkPermsAndNavigate(path, props)}
        exact
        path={path}
        component={component}
      />
    );
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route path={'/'} component={({ location, history }) => {
            return (
              <div className={css(Styles.page)}>
                {this.state.appUser &&
                  <Header
                    path={history.location.pathname}
                    className={css(Styles.header)}
                    currentUser={this.state.appUser}
                    onDashboardClick={() => this.onDashboardClick()}
                    onLogoutClick={() => this.onLogoutClick()} />
                }

                <div className={css(Styles.content)}>
                  {this.renderRoute(history)}
                </div>

                <Footer className={css(Styles.footer)} />

              </div>
            )
          }} />
        </MuiThemeProvider>
      </Router>
    );
  }

  renderRoute(history) {
    Controller.get().setNavigator(history);
    return (
      <Switch>

        <PublicRoute exact path={'/login'} component={LoginPage} />
        <PublicRoute exact path={'/terms'} component={Terms} />
        <PublicRoute exact path={'/privacy'} component={Privacy} />
        <PublicRoute exact path={'/no-permission'} component={NoPermission} />

        {this.getProtectedRoute('/')}
        {<Route exact path={'/callback'} component={CallbackHandler} />}

        {this.getProtectedRoute('/orgs', Admin)}
        {this.getProtectedRoute('/orgs/:id', Users)}
        {this.getProtectedRoute('/orgs/:id/places', Places)}
        {this.getProtectedRoute('/orgs/:id/users', Users)}
        {this.getProtectedRoute('/orgs/:id/devices', Devices)}
        
        {window.localStorage.currentOrg && JSON.parse(window.localStorage.currentOrg) && JSON.parse(window.localStorage.currentOrg).type == "society" ? this.getProtectedRoute('/orgs/:id/guests', GuestUsers) :""}
        {this.getProtectedRoute('/orgs/:id/reports', OrgReports)}
        {this.getProtectedRoute('/orgs/:id/violations', Violations)}

        {this.getProtectedRoute('/orgs/:id/settings', Settings)}
        {this.getProtectedRoute('/orgs/:id/places/:placeid/settings', PlaceSettings)}
        {AppConfig.SHOW_DIRECT_LINK_EVENT &&
          this.getProtectedRoute('/orgs/:id/allEvents', Cams)
        }
        {this.getProtectedRoute('/places/:id', Place)}
        {this.getProtectedRoute('/places/:id/cams', Cams)}
        {AppConfig.allowLocal &&
          this.getProtectedRoute('/places/:id/cams/:camid', CamLive)
        }
        {!AppConfig.allowLocal &&
          this.getProtectedRoute('/places/:id/cams/:camid', CamLiveCloud)
        }

        {this.getProtectedRoute('/places/:id/cams/:camid/settings', CamSettings)}

        {this.getProtectedRoute('/events/:eventId', Event)}


        <Route component={NotFound} />
      </Switch>
    )
  }

  onLogoutClick() {
    if (AppConfig.allowLocal) {
      Controller.get().loginMgr().localSignout()
      Controller.get().userMgr().signOut()
    } else {
      Controller.get().logout()
    }
    this.setState({
      appUser: null
    })
  }

  onDashboardClick() {
    this.props.history.push('/orgs');
  }
}

const Styles = StyleSheet.create({
  page: {
  },
  header: {
    flex: '0 0 auto',
    position:"fixed !important"
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  footer: {
    flex: '0 0 auto',
  }
})

export default App;
