/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from '../../common/Controller';
import { adminData } from '../../data/AdminData.js'
import { CommonStyles } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { AppHelper } from '../../helpers/AppHelper.js'
import { Styles } from './Styles';

export class EventEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prioritySelected: this.props.prioritySelected
    }
  }

  onPrioritySelect = (item) => {
    this.setState({ prioritySelected: item })
  }


  render = () => {
    return (
      <Dialog
        onClose={this.props.closeEventEditor}
        open={this.props.eventEditor}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.editDialog),
          scrollPaper: css(Styles.scrollPaper)
        }}>

        <DialogTitle>
          <div className={css(Styles.eventItemHeaderContent)}>
            <p className={css(Styles.editDialogTitle)}>Event Details</p>
            <div className={css(Styles.eventLiveFeedContainer)} onClick={() => this.props.closeEventEditor()}>
              <img src={require('../../assets/images/close-img.svg')} className={css(Styles.addImg)} />
            </div>
          </div>
        </DialogTitle>

        <DialogContent className={css(Styles.editDialogContent)}>
          <div className={css(Styles.eventItemHeaderInnerContent)}>

            <div className={css(Styles.eventImageContent, Styles.editImageDialog)}>
              <img src={this.props.selectedEditItem.image} className={css(Styles.eventImage)} />
            </div>

            <TextField
              label='Event Name'
              className={css(Styles.editInputCss)}
              value={this.props.selectedEditItem.name}
              margin='normal'
              disabled={true}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: css(Styles.editTextFieldContentInput),
                }
              }}
              InputLabelProps={{
                classes: {
                  root: css(Styles.textFieldContent),
                }
              }}
            />

            <div className={css(Styles.eventPriorityContainer)}>
              {this.props.selectedEditItem.type &&
                <div style={{ marginRight: 20 }}>

                  <p className={css(Styles.editIncidentTitle)}>Event Priority</p>

                  <div className={css(Styles.eventItemType, Styles.eventItemTypePriority)}>
                    <p className={css(CommonStyles.margin)}>
                      {AppHelper.getPriorityStr(this.props.selectedEditItem.priority)}
                    </p>
                  </div>
                </div>
              }

              {this.props.selectedEditItem.priorityReported !== undefined &&
                Controller.get().userMgr().isAdminUser() &&
                <div className={css(Styles.reportedPriorityContainer)}>
                  <div>
                    <p className={css(Styles.editIncidentTitle)}>Reported Priority</p>
                    <div className={css(Styles.editIncidentType)}>
                      <p className={css(CommonStyles.margin, Styles.prioritySize)}>
                        {AppHelper.getPriorityStr(this.props.selectedEditItem.priorityReported)}
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>

            {this.props.selectedEditItem && this.props.selectedEditItem.tags &&
              <div className={css(Styles.tagsOuterContainer)}>
                <p className={css(Styles.editIncidentTitle, Styles.editIncidentTag)}>Tag</p>
                <div className={css(Styles.tagsInnerContainer)}>
                  {Object.keys(this.props.selectedEditItem.tags).map(key => {
                    return (
                      <div className={css(Styles.tagContainer, Styles.tagContainerDetail)}>
                        <p className={css(Styles.tagKey)}>{key}</p>
                        <p className={css(Styles.tagValue)}>{this.props.selectedEditItem.tags[key]}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            }


            <p className={css(Styles.editIncidentTitle)}>{Controller.get().userMgr().isAdminUser() ? 'Change Priority' : ''}</p>


            {this.props.selectedEditItem.priorityReported !== undefined && Controller.get().userMgr().isAdminUser() ?
              <div className={css(Styles.editAlert)}>
                {adminData.priorityValues.map(item => {
                  return (
                    <div className={css(Styles.editAlertContainer)}>
                      <div className={css(Styles.editAlertDotContainer)}>
                        <div className={css(Styles.editAlertDotContent)} style={{ backgroundColor: item.color }} />
                      </div>

                      <div className={css(Styles.editAlertLabel)}
                        onClick={() => this.onPrioritySelect(item)}
                        style={{ backgroundColor: this.state.prioritySelected.label === item.label ? item.color : AppTheme.primaryColor }}>
                        <p className={css(CommonStyles.margin)}>{item.label}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
              :
              <div className={css(Styles.editIncidentType)}>
                <p className={css(CommonStyles.margin)}>
                  {AppHelper.getPriorityStr(this.state.selectedEditItem.priority)}
                </p>
              </div>
            }

            {Controller.get().userMgr().isAdminUser() &&
              <div className={css(Styles.saveButtonOuterContainer)}>
                <div className={css(Styles.saveButtonContainer)}>
                  <Button
                    variant='contained'
                    onClick={(event) => this.props.editEvent(this.state.prioritySelected.value, 'save')}
                    className={css(Styles.saveButton)}>
                    SAVE EVENT
                    {this.state.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                  </Button>
                </div>

                <div className={css(Styles.saveButtonContainer)}>
                  <Button
                    variant='contained'
                    onClick={(event) => this.props.editEvent(this.state.prioritySelected.value, 'delete')}
                    className={css(Styles.saveButton)}>
                    DELETE EVENT
                    {this.state.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                  </Button>
                </div>
              </div>
            }
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}