export const appData = {
  orgFields: [
    {
      key: 'name',
      label: 'Org name',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Required' },
    },
    {
      key: 'type',
      label: 'Select place type',
      type: 'select_single',
      placement: 'right',
      required: { value: true, err: 'Required' },
      options: []
    },
    {
      key: 'phone',
      label: 'Contact Phone',
      type: 'input_text',
      placement: 'right',
      required: { value: false, err: 'Provide phone number' },
      phone: { value: true, err: 'Provide a valid phone' },
    },
    {
      key: 'email',
      label: 'Contact Email',
      type: 'input_text',
      placement: 'left',
      required: { value: false, err: 'Required' },
      email: { value: true, err: 'Provide a valid email' }
    },
    {
      key: 'logoUrl',
      label: 'Org logo',
      type: 'input_text',
      placement: 'full',
      required: { value: false, err: 'Required' },
    },
  ],
  orgColTitles: [
    {
      text: 'Name',
      key: 'name',
      type: 'text'
    },
    {
      text: 'Logo',
      key: 'logoUrl',
      type: 'image'
    }
  ],
  popOverFields: [
    {
      key: 'edit',
      title: 'Edit'
    },
    {
      key: 'delete',
      title: 'Delete'
    }
  ],
  vehiclePopOverFields: [
    {
      key: 'edit-vehicle',
      title: 'Edit'
    },
    {
      key: 'delete-vehicle',
      title: 'Delete'
    }
  ],
  auditPopOverFields: [
    {
      key: 'edit',
      title: 'Edit'
    },
    {
      key: 'delete',
      title: 'Delete'
    },
    {
      key: 'audit',
      title: 'Audit'
    }
  ],
  devicePopOverFieldsAdmin: [
    {
      key: 'edit',
      title: 'Add Org'
    },
    {
      key: 'delete',
      title: 'Delete Device'
    },
  ],
  devicePopOverFieldsOrgAdmin: [
    {
      key: 'edit',
      title: 'Add Place'
    },
  ],
  camConfigPopOverFields: [
    {
      key: 'edit',
      title: 'Edit'
    },
    {
      key: 'delete',
      title: 'Delete'
    },
    {
      key: 'config',
      title: 'Config'
    }
  ],
  placeTilePopOverFields: [
    {
      key: 'downloadReport',
      title: 'Download Report'
    },
    {
      key: 'edit',
      title: 'Edit'
    },
    {
      key: 'delete',
      title: 'Delete'
    }
  ],
  regularPopOverFields: [
    {
      key: 'viewInfo',
      title: 'View Info'
    },
    {
      key: 'downloadReport',
      title: 'Download Report'
    }
  ],
  userColTitles: [
    {
      type: 'multiple',
      items: [
        {
          text: 'NAME',
          key: 'name',
          type: 'text'
        },
        {
          text: 'E-MAIL ID',
          key: 'email',
          type: 'text'
        },
        {
          text: 'ROLE',
          key: 'role',
          type: 'list'
        }
      ]
    },

  ],
  userColMobileTitles: [
    {
      text: 'User Details',
      key: 'userDetails',
      type: 'text'
    },
  ],
  userColDesktopViewTitles: [
    {
      text: 'E-MAIL ID',
      key: 'email',
      type: 'text'
    },
    {
      text: 'ROLE',
      key: 'role',
      type: 'list'
    },
    {
      text: 'NAME',
      key: 'name',
      type: 'text'
    },
  ],
  deviceColTitles: [
    {
      type: 'multiple',
      items: [
        {
          text: 'Device ID',
          key: 'deviceId',
          type: 'text'
        },
        {
          text: 'NAME',
          key: 'name',
          type: 'text'
        },
        {
          text: 'TYPE',
          key: 'type',
          type: 'text'
        },
      ]
    },
  ],
  deviceColMobileTitles: [
    {
      text: 'Device Details',
      key: 'userDetails',
      type: 'text'
    },
  ],
  deviceColDesktopViewTitles: [
    {
      text: 'Device ID',
      key: 'deviceId',
      type: 'text'
    },
    {
      type: 'multiple',
      text: 'Device Details',
      items: [
        {
          text: 'NAME',
          key: 'name',
          type: 'text'
        },
        {
          text: 'ORG',
          key: 'orgName',
          type: 'text'
        },
        {
          text: 'PLACE',
          key: 'placeName',
          type: 'text'
        },
      ]
    },
    {
      text: 'TYPE',
      key: 'type',
      type: 'text'
    }
  ],
  userFieldEmail: {
    email: {
      key: 'email',
      label: 'Email Address',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Provide your email' },
      email: { value: true, err: 'Provide a valid email' }
    }
  },
  userFields: [
    {
      key: 'email',
      label: 'Email Address',
      type: 'input_text',
      placement: 'left',
      disabled: true,
      required: { value: true, err: 'Provide your email' },
      email: { value: true, err: 'Provide a valid email' }
    },
    {
      key: 'role',
      label: 'Select Role',
      placement: 'right',
      type: 'checkbox',
      required: { value: true, err: 'Provide a role' },
      options: [
        {
          name: 'ADMIN',
          key: 'ADMIN',
        },
        {
          name: 'REGULAR',
          key: 'REGULAR',
        },
        {
          name: 'RESIDENT',
          key: 'RESIDENT',
        }
      ],
    },
    {
      key: 'cred',
      label: 'New Password (required only for password change)',
      type: 'input_text',
      inputHide: true,
      placement: 'full',
      required: { value: false, err: 'Provide a password' },
    },
    {
      key: 'name',
      label: 'Name',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Provide your name' },
    },
    {
      key: 'phone',
      label: 'Phone',
      type: 'input_text',
      placement: 'right',
      required: { value: false, err: 'Provide phone number' },
      phone: { value: true, err: 'Provide a valid phone' },
    },
    {
      key: 'vehicleNumbers',
      label: 'Vehicle Numbers',
      type: 'input_text',
      placement: 'full',
      required: { value: false, err: 'Provide vechicle number' },
    }
  ],
  adminUserFields: [
    {
      key: 'email',
      label: 'Email Address',
      type: 'input_text',
      disabled: true,
      placement: 'left',
      required: { value: true, err: 'Provide your email' },
      email: { value: true, err: 'Provide a valid email' }
    },
    {
      key: 'name',
      label: 'Name',
      type: 'input_text',
      placement: 'right',
      required: { value: true, err: 'Provide your name' },
    },
    {
      key: 'phone',
      label: 'Phone',
      type: 'input_text',
      placement: 'left',
      required: { value: false, err: 'Provide phone number' },
      phone: { value: true, err: 'Provide a valid phone' },
    },
    {
      key: 'cred',
      label: 'Password (required only for password change)',
      type: 'input_text',
      inputHide: true,
      placement: 'right',
      required: { value: false, err: 'Provide a password' },
    }
  ],
  adminAuditFieldConfig: [
    {
      "key": "fromDate",
      "label": "From Date",
      "type": "input_text",
      "inputDate":true,
      "placement": "left",
      "required": {
        "value": true,
        "err": "Provide your from date"
      }
    },
    {
      "key": "toDate",
      "label": "To Date",
      "inputDate":true,
      "type": "input_text",
      "placement": "right",
      "required": {
        "value": true,
        "err": "Required"
      }
    }
  ],
  placeFields: [
    {
      key: 'name',
      label: 'Name',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Required' },
    },
    {
      key: 'type',
      label: 'Select type',
      type: 'select_single',
      placement: 'right',
      required: { value: true, err: 'Required' },
      options: []
    },
    /*{
      key: 'lat',
      label: 'Location Latitude',
      type: 'input_text',
      placement: 'left',
      required: { value: false, err: 'Required' },
    },
    {
      key: 'long',
      label: 'Location longitude',
      type: 'input_text',
      placement: 'right',
      required: { value: false, err: 'Required' },
    },*/
    {
      key: 'logoUrl',
      label: 'Logo link',
      type: 'input_text', // Updated
      placement: 'full',
      required: { value: false, err: 'Required' },
    }
  ],
  camFields: [
    {
      key: 'name',
      label: 'Name',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Required' },
    },
    {
      key: 'type',
      label: 'Select type',
      type: 'select_single',
      placement: 'right',
      required: { value: true, err: 'Required' },
      options: []
    },
    {
      key: 'urlVideo',
      label: 'Camera live video URL',
      type: 'input_text',
      placement: 'left',
      required: { value: false, err: 'Required' },
    },
    {
      key: 'flags',
      label: 'Flags',
      type: 'input_text',
      placement: 'right',
      required: { value: false, err: 'Required' },
    },
    {
      key: 'urlJpeg',
      label: 'Camera live image URL',
      type: 'input_text',
      placement: 'left',
      required: { value: false, err: 'Required' },
    }
  ],
  reportFields: {
    selectCam: {
      key: 'selectedCamIds',
      type: 'checkbox',
      options: []
    },
    eventFields: {
      key: 'eventFields',
      label: 'Event fields',
      labelValue: 'eventFieldLabel',
      type: 'text',
      options: []
    },
    eventImages: {
      key: 'eventImages',
      label: 'Event Images',
      type: 'text',
      options: []
    },
    fromDateTs: {
      key: 'fromDateTs',
      label: 'From',
      type: 'text',
    },
    toDateTs: {
      key: 'toDateTs',
      label: 'To',
      type: 'text'
    }
  },
  violationsField:{
    reasonForViolation: {
      key: 'reasonForViolation',
      label: 'Reason',
      type: 'text',
      options: []
    },
  }
}

export const liveStreamingIntervals = [
  {
    label: 'LIVE',
    value: 'live'
  },
  {
    label: '00:00 AM',
    value: '00:00'
  },
  {
    label: '03:00 AM',
    value: '03:00'
  },
  {
    label: '06:00 AM',
    value: '06:00'
  },
  {
    label: '09:00 AM',
    value: '09:00'
  },
  {
    label: '12:00 PM',
    value: '12:00'
  },
  {
    label: '03:00 PM',
    value: '15:00'
  },
  {
    label: '06:00 PM',
    value: '18:00'
  },
  {
    label: '09:00 PM',
    value: '21:00'
  },
]
