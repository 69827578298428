/* eslint-disable */ 
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  AppTheme,
} from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import {
  StyleSheet,
  css
} from 'aphrodite';

export class CustomDialog extends Component {

  constructor(props) {
    super(props);
    this.labelByAction = {
      lookup: 'NEXT',
      delete: 'DELETE',
      add: 'SAVE',
      upload: 'UPLOAD'
    }
    this.state = {
    }
  }

  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.dialogOpen}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
      >
        <DialogTitle className={css(Styles.dialogTitle)}>
          <div className={css(Styles.dialogTitleContainer)}>
            <p className={css(Styles.dialogTitleContent)}>{this.props.dialogTitle}</p>
            <div className={css(Styles.closeIconContainer)} onClick={this.props.onClose}>
              <img src={require('../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={css(Styles.dialogContent)}>
          <div className={css(Styles.fieldsContainer)}>
            {this.props.confirmDeleteView &&
              <div>
                <p className={css(Styles.deleteConfirmMsg)}>Are you sure you want to delete member {this.props.selectedItem.email}?</p>
                <p className={css(Styles.deleteConfirmMsg)}>This action cannot be undone.</p>
              </div>
            }
            {!this.state.confirmDeleteView &&
              <div className={css(Styles.fieldsInnerContainer)}>
                {this.props.children}
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          <div className={css(Styles.dialogActionsInnerContainer)}>
            {this.props.errMsg &&
              <p className={css(Styles.deleteMsg)}>{this.props.errMsg}</p>
            }
            <div className={css(Styles.dialogActionsInnerContainerButton)}>
              {this.props.confirmDeleteView &&
                <Button
                  disabled={this.props.inProcess}
                  variant='contained'
                  onClick={e => this.props.deleteItem()}
                  className={css(Styles.buttonStyle)}>
                  DELETE
                  {this.props.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                </Button>
              }
              {!this.props.confirmDeleteView && this.props.action &&
                <Button
                  disabled={this.props.inProcess}
                  variant='contained'
                  onClick={this.props.action === 'delete' ?
                    e => this.props.showConfirmDeleteView() :
                    e => this.props.submitItem()}
                  className={css(Styles.buttonStyle)}>
                  {this.labelByAction[this.props.action]}
                  {this.state.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                </Button>
              }
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 850,
    '@media(max-width: 768px)': {
      minWidth: '-webkit-fill-available',
      margin: 5
    }
  },
  scrollPaper: {
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      overflow: 'scroll',
      justifyContent: 'flex-start'
    }
  },
  dialogTitle: {
    backgroundColor: AppTheme.white,
    padding: 20,
  },
  dialogTitleContainer: {
    display: 'flex'
  },
  dialogTitleContent: {
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default'
  },
  closeIconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogContent: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
  },
  fieldsContainer: {
    width: '80%'
  },
  fieldsInnerContainer: {
    display: 'flex'
  },
  dialogActions: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    padding: 24,
    paddingTop: 0
  },
  dialogActionsInnerContainer: {
    width: '80%',
    display: 'flex',
  },
  dialogActionsInnerContainerButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteMsg: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    fontWeight: 'bold',
    color: AppTheme.white,
    fontSize: 15,
    cursor: 'defalut',
    marginLeft: 8
  },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  circulerProgress: {
    marginLeft: 10,
    color: AppTheme.white
  },
  deleteConfirmMsg: {
    color: AppTheme.white,
    cursor: 'default'
  },
})