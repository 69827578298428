/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
/* uv-prod */
export const AppConfigCustom = {
  "APPAK": "web-12a116f7-d6cc-41b5-97d9-e32bf3f4becf",                             //web-key from incomingAPIs.json
  "CLOUD_REGION": "ap-south-1",
  "COGNITO_USER_POOL": "ap-south-1_DbR1B5oQ8",
  "COGNITO_USER_POOL_CLIENT_ID": "170sskhj716eqfhb99nenp1oki",
  "ENDPOINT": "https://api.staging.remotifai.com",     //Invoke URL of ApiGateway
  "ES_ENDPOINT": "https://api.staging.remotifai.com",   //Invoke URL of ApiGateway
  "allowCamConfig": false,
  "allowLocal": false,
  "emailSuffix" : "@remotifai.com",
  "advancedShieldSettingUrl":"http://ec2-13-232-249-50.ap-south-1.compute.amazonaws.com/",
  "frontendSecretKey":"uvstaging-dashboard",
  "allowAuditConfig":true,
  "allowEventReviews":true,
  "autoUpdateEvent": false,
  "eventQueryLimit": 1000,
  "eventDownloadLimit": 1000,
  "allowBoundingBox":false,
  "agentStatusTimer":5, // in mins
  "agentStatusEnabled":true,
  "dashboardEventQueryLimit": 100,
  "SHOW_VIOLATION":true,
  "ENABLE_REFRESH_VIOLATION":true,
  "eventRefreshInterval": 10000,
  "imageFeedRefreshInterval": 2000,
  "parkingFeedRefreshInterval": 5000,
  "jpegPlayerRefreshInterval": 2000,
  "IDLE_TIMEOUT" : 1000*60*30,
  "SHOW_DIRECT_LINK_EVENT":false,
  "ENABLE_REPORT_DOWNLOAD": true,
  "PDF_LIMIT":9000,
  "EXCEL_LIMIT":30000,
  "NAME": "Eagle Eye Networks",
  "mapK": "AhBVLeZEl9lnaie15GuW_pVIAUAMcmsWT-IGiDBmVLmBIVsaG0hMn9bm6ZN_yvDj",
  "viewMode": "List",
  "adminDashboard": "/orgs",
  "ES_EVENT_TYPES": {},
  "DEFAULT_ZONEINFO": 'Asia/Calcutta',
  "userDefaultSettings": {
    ALERT_EMAIL: "OFF",
    EVENT_VISIBILITY: "0",
    EVENT_EMAIL_ALERT: "3",
    CAMERAS: [],
  },
  "userRoles": [
    {
      name: 'ADMIN',
      key: 'ADMIN',
    },
    {
      name: 'REGULAR',
      key: 'REGULAR',
    },
    {
      name: 'RESIDENT',
      key: 'RESIDENT',
    },
  ],
  placeTypes: [],
  camTypes: [],
  orgTypes: []
}