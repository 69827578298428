/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';

import { AppConfig } from '../AppConfig';
import { AppTheme, AppStyles } from '../styles/AppTheme';

export class Footer extends Component {
  render() {
    return(
      <footer className={css(Styles.footer)}>
        <p className={css(Styles.footerContent)}>© {AppConfig.NAME} {moment().year()} Version {AppConfig.Version}</p>
      </footer>
    );
  }
}

const Styles = StyleSheet.create({
  footer: {
    minHeight: 13,
    padding: 5,
    backgroundColor: '#2C3548',
    textAlign: 'center',
    border: '1px solid' + AppTheme.secondaryColor,
  },
  footerContent: {
    margin: 0,
    fontSize: 10,
    color: AppTheme.white,
    opacity: 0.3
  }
})