/* eslint-disable */ 
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CheckboxComponent } from './CheckboxComponent';
import { TextInputComponent } from './TextInputComponent';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { SelectComponentV2 } from './SelectComponentV2';
import { ImageUploaderComponent } from './ImageUploaderComponent';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
// import Icon from '@material-ui/core/Icon'
import MomentUtils from '@date-io/moment'
import momentTz from 'moment-timezone'
import InputAdornment from '@material-ui/core/InputAdornment'

import {
  AppTheme,
  AppStyles
} from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import {
  StyleSheet,
  css
} from 'aphrodite';
import { ImageUploader } from '../common/components/ImageUploader';
import { TogglerComponent } from './TogglerComponent';

export class CustomFormFieldsDialogV2 extends Component {

  constructor(props) {
    super(props);
    this.responseByKey = this.props.responseByKey || {}
    this.labelByAction = {
      lookup: 'NEXT',
      delete: 'DELETE',
      add: 'SAVE',
      edit: 'SAVE',
      upload: 'UPLOAD'
    }
    this.now = new Date();
    this.state = {
      dummy: true, //TODO
      expireAt: ''
    }
    this.responseByKey['alertEnabled'] = false;
  }

  setValue = (value, key) => {
    this.responseByKey[key] = value
    this.setState({
      dummy: !this.state.dummy
    })
  }

  handleDateChange = (e, key) => {
   this.setState({
     expireAt: e.toISOString()
   })
   this.responseByKey[key] = e.toISOString()
  }

  onSubmit = () => {
    this.validateFieldsAndSubmit()
  }

  validateFieldsAndSubmit() {
    let valid = true
    for (const formField of this.props.formFields) {
      if (ValidationHelper.validateFields(formField, this.responseByKey[formField.key])) {
        valid = false
      }
    }
    if (valid) {
      this.props.onSuccess(this.responseByKey)
    }
  }

  handleUploadSuccess(fieldKey, signedUploadInfo) {
    if (signedUploadInfo && signedUploadInfo.link) {
      this.setValue(signedUploadInfo.link, fieldKey)
    }
  }

  getFormFieldContent = (formField) => {
    if (formField.type === 'input_text') {
      if (this.props.action === 'viewInfo' && formField.key === 'logoUrl') {
        return null
      } else {
        return (
          <TextInputComponent
            key={formField.key}
            hidden = {formField.show && formField.show.value && formField.show.key && this.responseByKey[formField.show.key] != formField.show.value}
            formField={formField}
            value={this.responseByKey[formField.key]}
            enableValidation={this.props.enableValidation}
            setValue={this.setValue}
            disabled={this.props.formDisabled || formField.disabled}
            action={this.props.action}
          />
        )
      }
    } else if (formField.type === 'checkbox') {
      return (
        <CheckboxComponent
          disableSelectAll={true}
          formField={formField}
          value={this.responseByKey[formField.key] ? this.responseByKey[formField.key] : []}
          enableValidation={this.props.enableValidation}
          setValue={this.setValue.bind(this)}
          disabled={this.props.formDisabled}
          action={this.props.action}
        />
      )
    } else if (formField.type === 'select_single') {
      return (
        <SelectComponentV2
          key={formField.key}
          formField={formField}
          value={this.responseByKey[formField.key]}
          setValue={this.setValue}
          enableValidation={this.props.enableValidation}
          disabled={this.props.formDisabled || formField.disabled}
          action={this.props.action}
        />
      )
    } else if (formField.type === 'upload_image') {
      return (
        <ImageUploaderComponent
          formField={formField}
          value={this.responseByKey[formField.key]}
          handleUploadSuccess={this.handleUploadSuccess.bind(this)}
        />
      )
    } else if (formField.type === 'toggler') {
      return (
        <TogglerComponent
          formField={formField}
          setValue={this.setValue.bind(this)}
          value={this.responseByKey[formField.key]}
          handleUploadSuccess={this.handleUploadSuccess.bind(this)}
        />
      )
    } 

    else if (formField.type === 'input_date') {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            autoOk
            variant="filled"
            enableValidation={this.props.enableValidation}
            color={AppTheme.white}
            helperText={!this.props.enableValidation ? '' : ValidationHelper.validateFields(formField, this.state.value)}
            key={formField.key}
            id={formField.key}
            name={formField.key}
            style={{ margin: "8px", color: "white" }}
            value={momentTz(this.responseByKey['expireAt'])}
            onChange={e => this.handleDateChange(e, formField.key)}
            label={formField.label}
            minDate={this.now}
            format="DD MMM YYYY, HH:mm"
            InputProps={{
              disableUnderline: true,
              style: {
                ...{
                  backgroundColor: AppTheme.secondaryColor,
                  color: AppTheme.white,
                  paddingLeft: 8
                },
              },
              classes: {
                root: css(
                  Styles.headerTextFieldContentInput,
                  Styles.textFieldContentInputText,
                  Styles.dateInputField
                ),
                input: css(Styles.input),
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img
                      src={require("../assets/images/dateIcon-img.svg")}
                      className={css(Styles.controlImg, Styles.dateControlImg)}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{
              style: {
                ...{
                  color: AppTheme.white,
                  zIndex: 1,
                  paddingLeft: 8,
                },
              },
            }}
            InputLabelProps={{
              style: {
                ...{
                  color: AppTheme.white,
                  zIndex: 1,
                  paddingLeft: 8,
                  opacity: 0.6,
                },
              },
              classes: {
                root: css(Styles.headerTextFieldContent),
              },
            }}
            className={css(Styles.helperTextStyles)}
          />
        </MuiPickersUtilsProvider>
      );

    }
  }

  generateContent = () => {
    const leftFields = []
    const rightFields = []
    const fullLengthFields = []

    for (const formField of this.props.formFields) {
      if (formField.placement === 'left') {
        leftFields.push(this.getFormFieldContent(formField))
      } else if (formField.placement === 'right') {
        rightFields.push(this.getFormFieldContent(formField))
      } else if (formField.placement === 'full') {
        fullLengthFields.push(this.getFormFieldContent(formField))
      }
    }

    return (
      <div className={css(Styles.fieldsOuterContainer)}>
        <div className={css(Styles.fieldsInnerContainerOne)}>
          <div className={css(Styles.fieldsOuterContainerSpec)}>
            {leftFields}
          </div>
          <div className={css(Styles.fieldsOuterContainerSpec)}>
            {rightFields}
          </div>
        </div>
        <div className={css(Styles.fieldsOuterContainer)}>
          {fullLengthFields}
        </div>
      </div>
    )
  }
  render() {
    this.responseByKey = this.props.responseByKey || {}
    return (
      <Dialog
        disableBackdropClick={true}
        onClose={this.props.onClose}
        open={this.props.dialogOpen}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper),
          paper: css(Styles.dialogPaper)
        }}
      >
        <DialogTitle className={css(Styles.dialogTitle)}>
          <div className={css(Styles.dialogTitleContainer)}>
            <p className={css(Styles.dialogTitleContent)}>{this.props.dialogTitle}</p>
            <div className={css(Styles.closeIconContainer)} onClick={this.props.onClose}>
              <img src={require('../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={css(Styles.dialogContent)}>
          <div className={css(Styles.fieldsContainer)}>
            {this.props.confirmDeleteView &&
              <div>
                <p className={css(Styles.deleteConfirmMsg)}>Are you sure you want to delete?</p>
                <p className={css(Styles.deleteConfirmMsg)}>This action cannot be undone.</p>
              </div>
            }
            {this.props.confirmOverwriteView &&
              <div>
                <p className={css(Styles.deleteConfirmMsg)}>Are you sure you want to Overwrite all agents?</p>
                <p className={css(Styles.deleteConfirmMsg)}>This action cannot be undone.</p>
              </div>
            }
            {!this.props.confirmDeleteView &&
              <div className={this.props.action === 'viewInfo' ?
                css(Styles.fieldsInnerMainContainer) : css(Styles.fieldsInnerMainContainer, Styles.fieldsViewInnerMainContainer)}>
                {this.props.formFields && this.props.formFields.map((item, index) => {
                  return (
                    (item.key === 'logoUrl' && this.props.action === 'viewInfo') &&
                    <div
                      key={index}
                      className={css(Styles.fieldsInnerMainContent)}>
                      <p className={css(Styles.fieldsInnerLabelContainer)}>Site Image</p>
                      <div className={css(Styles.fieldsInnerImgContainer)}>
                        <img
                          src={this.responseByKey.logoUrl ? this.responseByKey.logoUrl : require('../assets/images/location-img.svg')}
                          onError={(e) => {
                            e.target.src = 'https://uv-misc-test.s3.ap-south-1.amazonaws.com/location-img.svg'
                          }}
                          className={css(Styles.fieldsInnerImgContent)} />
                      </div>
                    </div>
                  )
                })}
                <div className={css(Styles.fieldsInnerContainer)}>
                  {this.props.formFields &&
                    this.generateContent()
                  }
                </div>
              </div>
            }
          </div>
        </DialogContent>
        {this.props.action !== 'viewInfo' &&
          <DialogActions className={css(Styles.dialogActions)}>
            <div className={css(Styles.dialogActionsInnerContainer)}>
              {this.props.errMsg &&
                <p className={css(Styles.deleteMsg)}>{this.props.errMsg}</p>
              }
              <div className={css(Styles.dialogActionsInnerContainerButton)}>
                {this.props.confirmDeleteView &&
                  <Button
                    disabled={this.props.inProcess}
                    variant='contained'
                    onClick={e => this.props.deleteItem()}
                    className={css(Styles.buttonStyle)}>
                    DELETE
                    {this.props.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                  </Button>
                }
                {!this.props.confirmDeleteView && this.props.action && !this.props.auditTrailEnabled &&
                  <Button
                    disabled={this.props.inProcess}
                    variant='contained'
                    onClick={this.props.action === 'delete' ?
                      e => this.props.showConfirmDeleteView() :
                      e => this.onSubmit()}
                    className={css(Styles.buttonStyle)}>
                    {this.labelByAction[this.props.action]}
                    {this.props.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                  </Button>
                }
                {this.props.auditTrailEnabled &&
                  <Button
                    disabled={this.props.inProcess}
                    variant='contained'
                    onClick={e => this.props.downloadAuditTrail()}
                    className={css(Styles.buttonStyle)}>
                    Download
                    {this.props.inProcess && <CircularProgress color='primary' size={20} className={css(Styles.circulerProgress)} />}
                  </Button>
                }
                {this.props.confirmOverwriteView &&
                  <Button
                    variant='contained'
                    onClick={e => this.props.confirmOverWrite()}
                    className={css(Styles.buttonStyle)}>
                    Confirm
                  </Button>
                }
              </div>
            </div>
          </DialogActions>
        }
      </Dialog>
    )
  }
}

const Styles = StyleSheet.create({
  helperTextStyles: {
    root: {
      margin: 4,
      color:'white',
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error" :{
        color: AppTheme.white,
      },
    },
  },
  dialog: {
    minWidth: 850,
    '@media(max-width: 768px)': {
      minWidth: '-webkit-fill-available',
      margin: 5
    }
  },
  dialogPaper: {
    margin: 0,
    '@media(max-width: 768px)': {
      marginLeft: 10,
      marginRight: 10
    }
  },
  scrollPaper: {
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      overflow: 'scroll',
      justifyContent: 'flex-start'
    }
  },
  dialogTitle: {
    backgroundColor: AppTheme.white,
    padding: 20,
    '@media(max-width: 767px)': {
      padding: '20px 13px'
    }
  },
  dialogTitleContainer: {
    display: 'flex'
  },
  dialogTitleContent: {
    margin: 0,
    fontSize: AppTheme.fontSizeTitle,
    cursor: 'default',
    flex: 1,
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dialogContent: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    padding: '25px 25px 0px',
    '@media(max-width: 767px)': {
      padding: '5px 5px 0px',
    }
  },
  fieldsContainer: {
    width: '100%'
  },
  fieldsInnerLabelContainer: {
    margin: 0,
    color: AppTheme.white,
    opacity: 0.6,
    fontSize: 12,
    marginBottom: 5
  },
  fieldsInnerMainContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  fieldsViewInnerMainContainer: {
    justifyContent: 'center'
  },
  fieldsInnerMainContent: {
    marginTop: 4,
    marginRight: 60
  },
  fieldsInnerImgContainer: {
    maxWidth: 180,
    maxHeight: 180
  },
  fieldsInnerImgContent: {
    width: '100%',
    height: '100%'
  },
  fieldsInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  dialogActions: {
    backgroundColor: AppTheme.primaryBackground,
    display: 'flex',
    justifyContent: 'center',
    padding: 24,
    paddingTop: 0,
    marginTop: -5,
    '@media(max-width: 767px)': {
      padding: 5,
      paddingBottom: 20
    }
  },
  dialogActionsInnerContainer: {
    width: '100%',
    display: 'flex',
  },
  dialogActionsInnerContainerButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteMsg: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    fontWeight: 'bold',
    color: AppTheme.white,
    fontSize: 15,
    cursor: 'defalut',
    marginLeft: 8
  },
  buttonStyle: {
    marginRight: 8,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: AppTheme.white,
    fontWeight: 'bold',
    fontSize: 11,
    padding: '5px 16px'
  },
  circulerProgress: {
    marginLeft: 10,
    color: AppTheme.white
  },
  deleteConfirmMsg: {
    color: AppTheme.white,
    cursor: 'default'
  },
  fieldsOuterContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  fieldsOuterContainerSpec: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    // '@media(max-width: 767px)': {
    //   width: '100%',
    // }
  },
  fieldsInnerContainerOne: {
    display: 'flex',
    width: '100%',
    // '@media(max-width: 767px)': {
    //   flexDirection: 'column'
    // }
  },
  imageUploaderContainer: {
    backgroundColor: AppTheme.primaryBackground,
    margin: 8
  },
  dateInput: {
    margin: 8
  }
})